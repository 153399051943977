import { useState } from "react";
import Subtitle from "components/universal/subtitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "api/hooks/apiHook";
import { PriceList } from "api/types/price";
import { INCHES } from "values/values";
import { MILLIMETERS } from "values/values";
import { getMeasurementType } from "values/values";
import { getMaterialTypeDisplay } from "values/material";
import { PRICE_LIST } from "api/types/update";
import { islandStyle } from "styles/style";

interface Props {
    start_update: (type: number, data: PriceList) => void,
    price_list: PriceList
}

export default function PriceListDetails({start_update, price_list}: Props){
    const { available_regions } = useAppSelector((state) => state.price); 
    const [price_list_update, setPriceListUpdate] = useState<PriceList>(price_list);
    const region_loaded = available_regions && available_regions.length > 0;

    const handleName = (name: string) => {
        const new_price_list: PriceList = {...price_list_update, name: name};
        setPriceListUpdate(new_price_list);
        start_update(PRICE_LIST, new_price_list);
    }

    const handleRegion = (region: string) => {
        if(isNaN(Number(region)) === false){
            const new_price_list: PriceList = {...price_list_update, region: Number(region)};
            setPriceListUpdate(new_price_list);
            start_update(PRICE_LIST, new_price_list);
        } 
    }

    const handleUnits = (units: string) => {
        const new_price_list: PriceList = {...price_list_update, units: units};
        setPriceListUpdate(new_price_list);
        start_update(PRICE_LIST, new_price_list);
    }

    const handleStatus = (status: string) => {
        const new_price_list: PriceList = {...price_list_update};
        if(status === "A"){
            new_price_list.status = true;
        }
        else{
            new_price_list.status = false;
        }
        setPriceListUpdate(new_price_list);
        start_update(PRICE_LIST, new_price_list);
    }

    const handle2CMMaterialMarkup = (markup: string) => {
        if(!isNaN(Number(markup))){
            const new_price_list: PriceList = {...price_list_update, cm2_material_markup: Number(markup)};
            setPriceListUpdate(new_price_list);
            start_update(PRICE_LIST, new_price_list);
        }
    }

    const handle3CMMaterialMarkup = (markup: string) => {
        if(!isNaN(Number(markup))){
            const new_price_list: PriceList = {...price_list_update, cm3_material_markup: Number(markup)};
            setPriceListUpdate(new_price_list);
            start_update(PRICE_LIST, new_price_list);
        }
    }

    const handlePorcelainMaterialMarkup = (markup: string) => {
        if(!isNaN(Number(markup))){
            const new_price_list: PriceList = {...price_list_update, cm3_material_markup: Number(markup)};
            setPriceListUpdate(new_price_list);
            start_update(PRICE_LIST, new_price_list);
        }
    }

    return (
        <Paper sx={islandStyle}>
            <Stack sx={{paddingTop: 1}}>
                <Subtitle 
                    endProps={<Box sx={{marginBottom: 1}}></Box>}
                    subtitle="Details"/>
                <Grid container spacing={2} sx={{paddingTop: 1}}>
                    <Grid item xs={2}>
                        <Typography variant="body2" sx={{paddingLeft: 1}}>
                            <b>Region:</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body2">
                            {price_list.region_data ? price_list.region_data.name : ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2" sx={{paddingLeft: 1}}>
                            <b>Material Type:</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body2" sx={{marginBottom: 6}}>
                            {getMaterialTypeDisplay(price_list.material_type)}
                        </Typography>
                    </Grid>
                </Grid>
                <Stack direction="row" spacing={6}>
                    <FormControl sx={{width: 300}}>
                        <TextField
                            sx={{marginBottom: 2}}
                            onChange={(e) => handleName(e.target.value)}
                            size="small"
                            label="Name"
                            defaultValue={price_list.name} />
                        {
                            region_loaded ?
                            <TextField
                                sx={{marginBottom: 2}}
                                onChange={(e) => handleRegion(e.target.value)}
                                size="small"
                                label="Region"
                                defaultValue={price_list.region}
                                select>
                                    {
                                        available_regions.map(region => {
                                            return (
                                                <MenuItem key={region.id} value={region.id}>
                                                    {region.name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                            </TextField> :
                            null
                            
                        }
                        <TextField
                            sx={{marginBottom: 2}}
                            onChange={(e) => handleUnits(e.target.value)}
                            size="small"
                            label="Units"
                            defaultValue={price_list.units}
                            select>
                                <MenuItem key={INCHES} value={INCHES}>
                                    {getMeasurementType(INCHES)}
                                </MenuItem>
                                <MenuItem key={MILLIMETERS} value={MILLIMETERS}>
                                    {getMeasurementType(MILLIMETERS)}
                                </MenuItem>
                        </TextField>
                        <TextField
                            sx={{marginBottom: 2}}
                            onChange={(e) => handleStatus(e.target.value)}
                            size="small"
                            label="Status"
                            defaultValue={price_list.status ? "A" : "I"}
                            select>
                                <MenuItem key="A" value="A">
                                    Active
                                </MenuItem>
                                <MenuItem key="I" value="I">
                                    Inactive
                                </MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl sx={{width: 300}}>
                        <TextField
                            sx={{marginBottom: 2}}
                            onChange={(e) => handle2CMMaterialMarkup(e.target.value)}
                            size="small"
                            label="2CM Markup"
                            defaultValue={price_list.cm2_material_markup} />
                        <TextField
                            sx={{marginBottom: 2}}
                            onChange={(e) => handle3CMMaterialMarkup(e.target.value)}
                            size="small"
                            label="3CM Markup"
                            defaultValue={price_list.cm3_material_markup} />
                        <TextField
                            sx={{marginBottom: 2}}
                            onChange={(e) => handlePorcelainMaterialMarkup(e.target.value)}
                            size="small"
                            label="Porcelain Markup"
                            defaultValue={price_list.porcelain_material_markup} />
                    </FormControl>
                </Stack>
            </Stack>
        </Paper>
    )
}