import React from "react";
import { useState } from "react";
import { createPriceList } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { PriceList } from "api/types/price";
import { PriceListError } from "api/types/price";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { getMaterialTypeDisplay } from "values/material";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { INCHES } from "values/values";
import { QUARTZ } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { NATURAL_STONE } from "values/material";

export default function CreatePriceList() {
    const dispatch = useAppDispatch();
    const { regions } = useAppSelector((state) => state.price);

    const empty_price_list: PriceList = {
        name: "",
        status: true,
        region: 0,
        units: INCHES,
        material_type: QUARTZ
    }

    const empty_price_list_errors: PriceListError = {
        name_error: "",
        name_has_error: false,
        region_error: "",
        region_has_error: false
    }

    const [open, setOpen] = useState<boolean>(false);
    const [price_list, setPriceList] = useState<PriceList>(empty_price_list);
    const [price_list_error, setPriceListError] = useState<PriceListError>(empty_price_list_errors);

    const handleName = (name: string) => {
        setPriceList({...price_list, name: name});
        setPriceListError({
            ...price_list_error,
            name_has_error: false,
            name_error: ""
        });
    }
    
    const handleStatus = (status: string) => {
        if(status === "A"){
            setPriceList({...price_list, status: true});
        }
        else if(status === "I"){
            setPriceList({...price_list, status: false});
        } 
    }

    const handleRegion = (region: number) => {
        setPriceList({...price_list, region: region});
        setPriceListError({
            ...price_list_error,
            region_error: "",
            region_has_error: false
        });
    }

    const handleUnits = (units: string) => {
        setPriceList({...price_list, units: units});
    }

    const handleMaterialType = (material_type: string) => {
        setPriceList({...price_list, material_type: material_type})
    }

    const handleCreate = () => {
        let error = false;
        let errors: PriceListError = empty_price_list_errors;

        if(price_list.name === undefined || price_list.name === null || price_list.name === ""){
            errors = {
                ...errors,
                name_error: "Please enter a name for this price list",
                name_has_error: true
            }
            error = true;
        }

        if(price_list.region === null || price_list.region === undefined || price_list.region < 1){
            errors = {
                ...errors,
                region_error: "Please choose a region",
                region_has_error: true
            }
            error = true;
        }

        if(error){
            setPriceListError(errors);
            return;
        }

        dispatch(createPriceList(price_list));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setPriceList(empty_price_list);
        setPriceListError(empty_price_list_errors);
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Price List</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <FormControl fullWidth>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            id="name"
                            label="Name"
                            error={price_list_error.name_has_error}
                            onChange={(e) => handleName(e.target.value)} />
                        <FormHelperText error id="name_error">{price_list_error.name_error}</FormHelperText>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            select
                            size="small"
                            variant="outlined"
                            defaultValue={0}
                            id="region"
                            label="Region"
                            error={price_list_error.region_has_error}
                            onChange={(e) => handleRegion(Number(e.target.value))}>
                                <MenuItem key={0} value={0}>
                                    {"      "}
                                </MenuItem>
                                {regions.map(region => {
                                    return (
                                        <MenuItem key={region.id} value={region.id}>
                                            {region.name}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        <FormHelperText error id="region_error">{price_list_error.region_error}</FormHelperText>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            select
                            defaultValue="IN"
                            id="units"
                            label="Units"
                            onChange={(e) => handleUnits(e.target.value)}>
                                <MenuItem key="IN" value="IN">
                                    Inches
                                </MenuItem>
                                <MenuItem key="MI" value="MI">
                                    Millimeters
                                </MenuItem>
                            </TextField>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            select
                            defaultValue={QUARTZ}
                            id="material_type"
                            label="Material Type"
                            onChange={(e) => handleMaterialType(e.target.value)}>
                                <MenuItem key={QUARTZ} value={QUARTZ}>
                                    {getMaterialTypeDisplay(QUARTZ)}
                                </MenuItem>
                                <MenuItem key={NATURAL_STONE} value={NATURAL_STONE}>
                                    {getMaterialTypeDisplay(NATURAL_STONE)}
                                </MenuItem>
                                <MenuItem key={ULTRA_COMPACT} value={ULTRA_COMPACT}>
                                    {getMaterialTypeDisplay(ULTRA_COMPACT)}
                                </MenuItem>
                            </TextField>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            select
                            defaultValue="A"
                            id="status"
                            label="Status"
                            onChange={(e) => handleStatus(e.target.value)}>
                                <MenuItem key={"A"} value={"A"}>
                                    {"Active"}
                                </MenuItem>
                                <MenuItem key={"I"} value={"I"}>
                                    {"Inactivate"}
                                </MenuItem>
                            </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
