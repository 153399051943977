import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

interface Props {
    saveBoolean(key: string, value: boolean): void
}

export default function Options({saveBoolean}: Props){
    return (
        <FormControl fullWidth sx={{paddingTop: 1, paddingLeft: 1}}>
            <FormGroup>
                <FormControlLabel 
                    label={<Typography variant="body2">Enabled</Typography>} 
                    control={
                        <Checkbox 
                            size="small"
                            onChange={(e) => saveBoolean("enabled", e.target.checked)}
                            defaultChecked />
                    } />
                <FormControlLabel 
                    control={
                        <Checkbox 
                            size="small"
                            onChange={(e) => saveBoolean("taxable", e.target.checked)}
                            defaultChecked />
                    } 
                    label={<Typography variant="body2">Taxable</Typography>}  />
            </FormGroup>
        </FormControl>
    )
}