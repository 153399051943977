import { BrowserRouter } from "react-router-dom";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import NavBar from "components/navigation/navbar";
import Home from "components/home/home";
import Account from "components/account/account";
import Accounts from "components/account/accounts";
import Regions from "components/regions/regions";
import RegionComponent from "components/regions/region";
import MiniDrawer from "components/drawer/mini_drawer";
import ChangePassword from "components/profile/change_password";
import ForgotPassword from "components/authentication/forgot_password";
import Materials from "components/material/materials";
import MaterialImporter from "components/material_importer/import";
import UploadInstanceComponent from "components/material_importer/upload_instance";
import LoginPage from "components/authentication/login_page";
import StaffSubscriptionSetupConfirmation from "components/account/confirmation";
import StaffSubscriptionSetupCancellation from "components/account/cancellation";
import CreateAccount from "components/account/create_account";
import ManageSuppliers from "components/material/assign";
import FeedbacksComponent from "components/feedback/feedbacks";
import FeedbackComponent from "components/feedback/feedback";
import BugsComponent from "components/feedback/bugs";
import BugComponent from "components/feedback/bug";
import PriceLists from "components/price/price_lists";
import PriceList from "components/price/price_list";
import { forgot_password_url } from "./endpoints";
import { home_url } from "./endpoints";
import { region_url } from "./endpoints";
import { create_account_url } from "./endpoints";
import { login_url } from "./endpoints";
import { material_url } from "./endpoints";
import { change_password_url } from "./endpoints";
import { manage_suppliers_url } from "./endpoints";
import { feedback_url } from "./endpoints";
import { bug_url } from "./endpoints";
import { material_import_url } from "./endpoints";
import { price_list_url } from "./endpoints";
import { account_url } from "./endpoints";

export default function MainRouter(){
    return (
        <BrowserRouter>
            <NavBar />
            <MiniDrawer />
            <Routes>
                <Route path={home_url}>
                    <Route index element={<Home />} />
                    <Route path={login_url}>
                        <Route index element={<LoginPage />} />
                    </Route>
                    <Route path={account_url}>
                        <Route index element={<Accounts />} />
                        <Route path=":id" element={<Account />} />
                    </Route>
                    <Route path={material_url}>
                        <Route index element={<Materials />} />
                    </Route>
                    <Route path={region_url}>
                        <Route index element={<Regions />} />
                        <Route path=":id" element={<RegionComponent />} />
                    </Route>
                    <Route path={price_list_url}>
                        <Route index element={<PriceLists />} />
                        <Route path=":id" element={<PriceList />}/>
                    </Route>
                    <Route path={change_password_url} element={<ChangePassword />} />
                    <Route path={forgot_password_url} element={<ForgotPassword />} />
                    <Route path={create_account_url}>
                        <Route index element={<CreateAccount />} />
                        <Route path="confirmation" element={<StaffSubscriptionSetupConfirmation />} />
                        <Route path="cancellation" element={<StaffSubscriptionSetupCancellation />} />
                    </Route>
                    <Route path={manage_suppliers_url}>
                        <Route index element={<ManageSuppliers />} />
                    </Route>
                    <Route path={feedback_url}>
                        <Route index element={<FeedbacksComponent />} />
                        <Route path=":id" element={<FeedbackComponent />} />
                    </Route>
                    <Route path={bug_url}>
                        <Route index element={<BugsComponent />} />
                        <Route path=":id" element={<BugComponent />} />
                    </Route>
                    <Route path={material_import_url}>
                        <Route index element={<MaterialImporter />} />
                        <Route path=":id" element={<UploadInstanceComponent />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}