import axios from 'axios';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from 'api/action_types/price';
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Region } from "api/types/price";
import { url_prefix } from 'settings';

const region_url: string = 'region';

export const getRegions = (for_fabricators: boolean, name?: string, status?: string) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_REGIONS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${region_url}/?`;

        if(for_fabricators){
            url += `for_fabricators=${for_fabricators}&`;
        }

        if(name && name !== ""){
            url+=`name=${name}&`;
        }

        if(status && status !== ""){
            url+=`active=${status}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_REGIONS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_REGIONS_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}

export const getRegion = (id: string) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_REGION_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${region_url}/${id}`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_REGION_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_REGION_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}

export const createRegion = (region: Region) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.CREATE_REGION_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${region_url}/`;

        try
        {
            const {data} = await axios.post(url, region, config);

            dispatch({
                type: ActionType.CREATE_REGION_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.CREATE_REGION_FAIL,
                    payload: err.message
                });

                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updateRegion = (region: Region, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_REGION_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${region_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, region, config);

            dispatch({
                type: ActionType.UPDATE_REGION_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPDATE_REGION_FAIL,
                    payload: err.message
                });

                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
            
        }
    }
}

export const deleteRegion = (id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.DELETE_REGION_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${region_url}/${id}/`;

        try
        {
            const {data} = await axios.delete(url, config);

            dispatch({
                type: ActionType.DELETE_REGION_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.DELETE_REGION_FAIL,
                    payload: err.message
                });

                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
            
        }
    }
}