import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { getPriceList } from "api/actions/price";
import { clearPriceList } from 'api/actions/price/price_list';
import { updateCooktopCutoutRange } from "api/actions/price";
import { updateMaterialToPriceList } from "api/actions/price";
import { updateSinkCutoutRange } from "api/actions/price";
import { updateInstallationCharge } from "api/actions/price";
import { updateLocalInstallationCharge } from 'api/actions/price/installation_charge';
import { updateFabricationCharge } from "api/actions/price";
import { updateLocalFabricationCharge } from 'api/actions/price/fabrication_charge';
import { updateSplash } from 'api/actions/price';
import { updateSplashRange } from 'api/actions/price';
import { updateLocalSplash } from 'api/actions/price/splash';
import { updateLocalSplashRange } from 'api/actions/price/splash_range';
import { updateFinishedEdge } from 'api/actions/price';
import { updateLocalFinishedEdge } from 'api/actions/price/finished_edge';
import { updateApplianceEdge } from 'api/actions/price';
import { updateLocalApplianceEdge } from 'api/actions/price/appliance_edge';
import { updateSinkCutout } from 'api/actions/price';
import { updateLocalMaterialToPriceList } from "api/actions/price/material_to_price_list";
import { updateLocalSinkCutoutRange } from 'api/actions/price/sink_cutout_range';
import { updateLocalSinkCutout } from "api/actions/price";
import { updateCooktopCutout } from 'api/actions/price';
import { updateLocalCooktopCutout } from "api/actions/price";
import { updateLocalCooktopCutoutRange } from 'api/actions/price/cooktop_cutout_range';
import { updateFaucetCutout } from 'api/actions/price';
import { updateLocalFaucetCutout } from 'api/actions/price/faucet_cutout';
import { updateOutletCutout } from 'api/actions/price';
import { updateLocalOutletCutout } from "api/actions/price/outlet_cutout";
import { updateNotchedCorner } from 'api/actions/price';
import { updateLocalNotchedCorner } from 'api/actions/price/notched_corner';
import { updateClippedCorner } from 'api/actions/price';
import { updateLocalClippedCorner } from 'api/actions/price/clipped_corner';
import { updateBumpedOutCorner } from 'api/actions/price/bumped_out_corner';
import { updateLocalBumpedOutCorner } from 'api/actions/price/bumped_out_corner';
import { updateRadiusCorner } from 'api/actions/price';
import { updateRadiusCornerRange } from 'api/actions/price/radius_corner_range';
import { updateLocalRadiusCorner } from 'api/actions/price/radius_corner';
import { updateLocalRadiusCornerRange } from 'api/actions/price/radius_corner_range';
import { updateInsideDiagonalCorner } from 'api/actions/price';
import { updateLocalInsideDiagonalCorner } from 'api/actions/price/inside_diagonal_corner';
import { updateRecessedDiagonalCorner } from 'api/actions/price';
import { updateLocalRecessedDiagonalCorner } from 'api/actions/price/recessed_diagonal_corner';
import { updateInsideRadiusCorner } from 'api/actions/price';
import { updateInsideRadiusCornerRange } from 'api/actions/price/inside_radius_corner_range';
import { updateLocalInsideRadiusCorner } from 'api/actions/price/inside_radius_corner';
import { updateLocalInsideRadiusCornerRange } from 'api/actions/price/inside_radius_corner_range';
import { updateLocalLaminateCountertopRemoval } from 'api/actions/price/countertop_removal';
import { updateLocalStoneCountertopRemoval } from 'api/actions/price/countertop_removal';
import { updateStoneCountertopRemoval } from 'api/actions/price/countertop_removal';
import { updateLaminateCountertopRemoval } from 'api/actions/price/countertop_removal';
import { updateBumpIn } from 'api/actions/price';
import { updateLocalBumpIn } from 'api/actions/price/bump_in';
import { updateBumpOut } from 'api/actions/price';
import { updateLocalBumpOut } from 'api/actions/price/bump_out';
import { updateBumpInArc } from 'api/actions/price';
import { updateLocalBumpInArc } from 'api/actions/price/bump_in_arc';
import { updateBumpOutArc } from 'api/actions/price';
import { updateLocalBumpOutArc } from 'api/actions/price/bump_out_arc';
import { updateFullArc } from 'api/actions/price';
import { updateLocalFullArc } from 'api/actions/price/full_arc';
import { getMaterials } from 'api/actions/material';
import { UpdateTime } from 'api/types/update';
import { updatePriceList } from 'api/actions/price';
import { PriceNavigation } from 'api/types/price';
import Title from "components/universal/title";
import TitleSpacer from 'components/universal/title_spacer';
import PriceListDetail from "./details";
import PriceListMaterial from './material';
import PriceListSplash from './splash';
import PriceListFabrication from './fabrication';
import PriceListInstallation from './installation';
import PriceListFinishedEdge from './finished_edge';
import PriceListApplianceEdge from './appliance_edge';
import PriceListSinkCutouts from './sink_cutout';
import PriceListCooktopCutouts from './cooktop_cutout';
import PriceListFaucetCutouts from './faucet_cutout';
import PriceListOutletCutouts from './outlet_cutouts';
import PriceListNotchedCorners from './notched_corners';
import PriceListClippedCorners from './clipped_corners';
import PriceListBumpedOutCorners from './bumped_out_corner';
import PriceListInsideDiagonalCorners from './inside_diagonal_corner';
import PriceListInsideRadiusCorners from './inside_radius_corner';
import PriceListRecessedDiagonalCorners from './recessed_diagonal_corner';
import PriceListBumpIn from './bump_in';
import PriceListBumpOut from './bump_out';
import PriceListBumpInArc from './bump_in_arc';
import PriceListBumpOutArc from './bump_out_arc';
import PriceListFullArc from './full_arc';
import PriceListRadiusCorner from './radius_corner';
import PriceListCountertopRemovals from './countertop_removal';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import PriceListDrawer from "../drawer";
import PuffLoader from 'animation/puff_loader';
import { PRICE_LIST } from 'api/types/update';
import { INSTALLATION } from 'api/types/update';
import { FABRICATION } from 'api/types/update';
import { SPLASH } from 'api/types/update';
import { SPLASH_RANGE } from 'api/types/update';
import { FINISHED_EDGE } from 'api/types/update';
import { APPLIANCE_EDGE } from 'api/types/update';
import { SINK_CUTOUT } from 'api/types/update';
import { SINK_CUTOUT_RANGE } from "api/types/update";
import { COOKTOP_CUTOUT } from 'api/types/update';
import { COOKTOP_CUTOUT_RANGE } from "api/types/update";
import { FAUCET_CUTOUT } from 'api/types/update';
import { OUTLET_CUTOUT } from 'api/types/update';
import { CLIPPED_CORNER } from 'api/types/update';
import { NOTCHED_CORNER } from 'api/types/update';
import { BUMPED_OUT_CORNER } from "api/types/update";
import { RADIUS_CORNER } from 'api/types/update';
import { RADIUS_CORNER_RANGE } from 'api/types/update';
import { INSIDE_RADIUS_CORNER } from 'api/types/update';
import { INSIDE_RADIUS_CORNER_RANGE } from 'api/types/update';
import { INSIDE_DIAGONAL_CORNER } from 'api/types/update';
import { RECESSED_DIAGONAL_CORNER } from 'api/types/update';
import { MATERIAL } from 'api/types/update';
import { BUMP_IN } from 'api/types/update';
import { BUMP_OUT } from 'api/types/update';
import { BUMP_IN_ARC } from 'api/types/update';
import { BUMP_OUT_ARC } from 'api/types/update';
import { FULL_ARC } from 'api/types/update';
import { STONE_COUNTERTOP_REMOVAL } from 'api/types/update';
import { LAMINATE_COUNTERTOP_REMOVAL } from 'api/types/update';
import { empty_price_navigation } from 'values/empty/price';
import { JSONIsEmpty } from 'functions/value';
import { mainContentAreaStyle } from 'styles/style';
import { primary_color } from "styles/style";
import { secondary_color } from 'styles/style';
import { third_color } from 'styles/style';
import { error_color } from 'styles/style';

export default function PriceListIndex() {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [update_pending, setUpdatePending] = useState<boolean>(false);
    const [start_times, setStartTimes] = useState<UpdateTime[]>([]);
    const [navigation, setNavigation] = useState<PriceNavigation>({...empty_price_navigation, show_price_list: true});
    const { price_list } = useAppSelector((state) => state.price);
    const price_list_loaded = JSONIsEmpty(price_list) === false;

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getPriceList(Number(id)));
            return () => {
                dispatch(clearPriceList());
            }
        }
    }, [dispatch, id]);

    useEffect(() => {
        if(price_list.id){
            dispatch(getMaterials(price_list.material_type, true));
        }
    }, [dispatch, price_list.id]);

    const start_update = (type: number, data: any) => {
        setUpdatePending(true);
        let exists: boolean = false;
        for(let i = 0; i < start_times.length; i++){
            if(start_times[i].type !== type){
                continue;
            }
            if(data.id === start_times[i].data.id){
                start_times[i] = {...start_times[i], time: Math.round(new Date().getTime() / 1000), data: data};
                exists = true;
            }
        }
        if(!exists){
            setStartTimes([...start_times, {
                time: Math.round(new Date().getTime() / 1000),
                type: type,
                data: data
            }])
        }
        local_update({
            time: Math.round(new Date().getTime() / 1000),
            type: type,
            data: data
        });
    }

    const local_update = (update_time: UpdateTime) => {
        if(update_time.data.id){
            switch(update_time.type){
                case PRICE_LIST:
                    break;
                case MATERIAL:
                    dispatch(updateLocalMaterialToPriceList(update_time.data));
                    break;
                case INSTALLATION:
                    dispatch(updateLocalInstallationCharge(update_time.data));
                    break;
                case FABRICATION:
                    dispatch(updateLocalFabricationCharge(update_time.data));
                    break;
                case SPLASH:
                    dispatch(updateLocalSplash(update_time.data));
                    break;
                case SPLASH_RANGE:
                    dispatch(updateLocalSplashRange(update_time.data));
                    break;
                case APPLIANCE_EDGE:
                    dispatch(updateLocalApplianceEdge(update_time.data));
                    break;
                case FINISHED_EDGE:
                    dispatch(updateLocalFinishedEdge(update_time.data));
                    break;
                case SINK_CUTOUT:
                    dispatch(updateLocalSinkCutout(update_time.data));
                    break;
                case SINK_CUTOUT_RANGE:
                    dispatch(updateLocalSinkCutoutRange(update_time.data));
                    break;
                case COOKTOP_CUTOUT:
                    dispatch(updateLocalCooktopCutout(update_time.data));
                    break;
                case COOKTOP_CUTOUT_RANGE:
                    dispatch(updateLocalCooktopCutoutRange(update_time.data));
                    break;
                case FAUCET_CUTOUT:
                    dispatch(updateLocalFaucetCutout(update_time.data));
                    break;
                case OUTLET_CUTOUT:
                    dispatch(updateLocalOutletCutout(update_time.data));
                    break;
                case CLIPPED_CORNER:
                    dispatch(updateLocalClippedCorner(update_time.data));
                    break;
                case NOTCHED_CORNER:
                    dispatch(updateLocalNotchedCorner(update_time.data));
                    break;
                case BUMPED_OUT_CORNER:
                    dispatch(updateLocalBumpedOutCorner(update_time.data));
                    break;
                case RADIUS_CORNER:
                    dispatch(updateLocalRadiusCorner(update_time.data));
                    break;
                case RADIUS_CORNER_RANGE:
                    dispatch(updateLocalRadiusCornerRange(update_time.data));
                    break;
                case INSIDE_RADIUS_CORNER:
                    dispatch(updateLocalInsideRadiusCorner(update_time.data));
                    break;
                case INSIDE_RADIUS_CORNER_RANGE:
                    dispatch(updateLocalInsideRadiusCornerRange(update_time.data));
                    break;
                case INSIDE_DIAGONAL_CORNER:
                    dispatch(updateLocalInsideDiagonalCorner(update_time.data));
                    break;
                case RECESSED_DIAGONAL_CORNER:
                    dispatch(updateLocalRecessedDiagonalCorner(update_time.data));
                    break;
                case BUMP_IN:
                    dispatch(updateLocalBumpIn(update_time.data));
                    break;
                case BUMP_OUT:
                    dispatch(updateLocalBumpOut(update_time.data));
                    break;
                case BUMP_IN_ARC:
                    dispatch(updateLocalBumpInArc(update_time.data));
                    break;
                case BUMP_OUT_ARC:
                    dispatch(updateLocalBumpOutArc(update_time.data));
                    break;
                case FULL_ARC:
                    dispatch(updateLocalFullArc(update_time.data));
                    break;
                case STONE_COUNTERTOP_REMOVAL:
                    dispatch(updateLocalStoneCountertopRemoval(update_time.data));
                    break;
                case LAMINATE_COUNTERTOP_REMOVAL:
                    dispatch(updateLocalLaminateCountertopRemoval(update_time.data));
                    break;
                default:
                    break;
            }
        }
    }

    const update = (update_time: UpdateTime) => {
        const new_start_times = [...start_times.filter(item => update_time.type === item.type && item.data.id !== update_time.data.id)];
        setStartTimes(new_start_times);
        
        if(update_time.data.id){
            switch(update_time.type){
                case PRICE_LIST:
                    dispatch(updatePriceList(update_time.data, update_time.data.id));
                    break;
                case MATERIAL:
                    dispatch(updateMaterialToPriceList(update_time.data, update_time.data.id));
                    break;
                case INSTALLATION:
                    dispatch(updateInstallationCharge(update_time.data, update_time.data.id));
                    break;
                case FABRICATION:
                    dispatch(updateFabricationCharge(update_time.data, update_time.data.id));
                    break;
                case SPLASH:
                    dispatch(updateSplash(update_time.data, update_time.data.id));
                    break;
                case SPLASH_RANGE:
                    dispatch(updateSplashRange(update_time.data, update_time.data.id));
                    break;
                case APPLIANCE_EDGE:
                    dispatch(updateApplianceEdge(update_time.data, update_time.data.id));
                    break;
                case FINISHED_EDGE:
                    dispatch(updateFinishedEdge(update_time.data, update_time.data.id));
                    break;
                case SINK_CUTOUT:
                    dispatch(updateSinkCutout(update_time.data, update_time.data.id));
                    break;
                case SINK_CUTOUT_RANGE:
                    dispatch(updateSinkCutoutRange(update_time.data, update_time.data.id));
                    break;
                case COOKTOP_CUTOUT:
                    dispatch(updateCooktopCutout(update_time.data, update_time.data.id));
                    break;
                case COOKTOP_CUTOUT_RANGE:
                    dispatch(updateCooktopCutoutRange(update_time.data, update_time.data.id));
                    break;
                case FAUCET_CUTOUT:
                    dispatch(updateFaucetCutout(update_time.data, update_time.data.id));
                    break;
                case OUTLET_CUTOUT:
                    dispatch(updateOutletCutout(update_time.data, update_time.data.id));
                    break;
                case CLIPPED_CORNER:
                    dispatch(updateClippedCorner(update_time.data, update_time.data.id));
                    break;
                case NOTCHED_CORNER:
                    dispatch(updateNotchedCorner(update_time.data, update_time.data.id));
                    break;
                case BUMPED_OUT_CORNER:
                    dispatch(updateBumpedOutCorner(update_time.data, update_time.data.id));
                    break;
                case RADIUS_CORNER:
                    dispatch(updateRadiusCorner(update_time.data, update_time.data.id));
                    break;
                case RADIUS_CORNER_RANGE:
                    dispatch(updateRadiusCornerRange(update_time.data, update_time.data.id));
                    break;
                case INSIDE_RADIUS_CORNER:
                    dispatch(updateInsideRadiusCorner(update_time.data, update_time.data.id));
                    break;
                case INSIDE_RADIUS_CORNER_RANGE:
                    dispatch(updateInsideRadiusCornerRange(update_time.data, update_time.data.id));
                    break;
                case INSIDE_DIAGONAL_CORNER:
                    dispatch(updateInsideDiagonalCorner(update_time.data, update_time.data.id));
                    break;
                case RECESSED_DIAGONAL_CORNER:
                    dispatch(updateRecessedDiagonalCorner(update_time.data, update_time.data.id));
                    break;
                case BUMP_IN:
                    dispatch(updateBumpIn(update_time.data, update_time.data.id));
                    break;
                case BUMP_OUT:
                    dispatch(updateBumpOut(update_time.data, update_time.data.id));
                    break;
                case BUMP_IN_ARC:
                    dispatch(updateBumpInArc(update_time.data, update_time.data.id));
                    break;
                case BUMP_OUT_ARC:
                    dispatch(updateBumpOutArc(update_time.data, update_time.data.id));
                    break;
                case FULL_ARC:
                    dispatch(updateFullArc(update_time.data, update_time.data.id));
                    break;
                case STONE_COUNTERTOP_REMOVAL:
                    dispatch(updateStoneCountertopRemoval(update_time.data, update_time.data.id));
                    break;
                case LAMINATE_COUNTERTOP_REMOVAL:
                    dispatch(updateLaminateCountertopRemoval(update_time.data, update_time.data.id));
                    break;
                default:
                    break;
            }
        }
        
        if(new_start_times.length === 0){
           setUpdatePending(false);
        }
    }

    return(
        <Box sx={mainContentAreaStyle}>
            {
                !price_list_loaded ?
                <Box sx={{position: "fixed", top: "50%", left: "50%"}}>
                    <PuffLoader 
                        color_1={primary_color}
                        color_2={secondary_color} 
                        color_3={third_color}
                        color_4={error_color} />
                </Box> :
                <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                        <Title 
                            title={price_list.name ? price_list.name : ""}
                            update_pending={update_pending}
                            start_times={start_times}
                            update={update}
                            endProps={<TitleSpacer />} />
                    </Grid>
                    <Grid item xs={8.5}>

                        {
                            price_list_loaded && navigation.show_price_list ? 
                            <PriceListDetail 
                                start_update={start_update}
                                price_list={price_list} /> :
                            null
                        }
                        
                        {
                            price_list_loaded && navigation.show_material ?
                            <PriceListMaterial
                                start_update={start_update} /> :
                            null
                        }
                        
                        {
                            price_list_loaded && navigation.show_splash ?
                            <PriceListSplash
                                start_update={start_update} /> :
                            null
                        }
                        

                        {
                            price_list_loaded && navigation.show_fabrication ?
                            <PriceListFabrication
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_installation ? 
                            <PriceListInstallation
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_finished_edge ? 
                            <PriceListFinishedEdge
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_appliance_edge ?
                            <PriceListApplianceEdge
                                start_update={start_update} /> :
                            null
                        }

                        
                        {
                            price_list_loaded && navigation.show_sink_cutouts ?
                            <PriceListSinkCutouts
                                start_update={start_update} /> :
                            null
                        }
                        
                        {
                            price_list_loaded && navigation.show_cooktop_cutouts ?
                            <PriceListCooktopCutouts
                                start_update={start_update} /> :
                            null
                        }
                        
                        {
                            price_list_loaded && navigation.show_faucet_cutouts ?
                            <PriceListFaucetCutouts
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_outlet_cutouts ?
                            <PriceListOutletCutouts
                                start_update={start_update} /> :
                            null
                        }
                        
                        {
                            price_list_loaded && navigation.show_notched_corners?
                            <PriceListNotchedCorners
                                start_update={start_update} /> :
                            null
                        }
                        
                        {
                            price_list_loaded && navigation.show_clipped_corners?
                            <PriceListClippedCorners
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_bumped_out_corners ?
                            <PriceListBumpedOutCorners
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_radius_corners ? 
                            <PriceListRadiusCorner
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_inside_diagonal_corners ? 
                            <PriceListInsideDiagonalCorners 
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_inside_radius_corners ?
                            <PriceListInsideRadiusCorners
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_recessed_diagonal_corners ?
                            <PriceListRecessedDiagonalCorners
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_bump_in ?
                            <PriceListBumpIn
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_bump_out ?
                            <PriceListBumpOut
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_bump_in_arc ?
                            <PriceListBumpInArc
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_bump_out_arc ?
                            <PriceListBumpOutArc
                                start_update={start_update} /> :
                            null
                        }

                        {
                            price_list_loaded && navigation.show_full_arc ?
                            <PriceListFullArc
                                start_update={start_update} /> :
                            null
                        }
                        {
                            price_list_loaded && navigation.show_countertop_removal ?
                            <PriceListCountertopRemovals
                                start_update={start_update} /> :
                            null
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <PriceListDrawer 
                            navigation={navigation}
                            setNavigation={setNavigation} />
                    </Grid>
                </Grid>
            }
        </Box>
    )
}
