import React from "react";
import { useState } from "react";
import { createMaterial } from "api/actions/material";
import { useAppDispatch } from 'api/hooks/apiHook';
import { Material } from "api/types/material";
import { MaterialError } from "api/types/price";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { empty_material } from "values/empty/material";
import { empty_material_errors } from "values/empty/material";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { QUARTZ } from "values/material";
import { NATURAL_STONE } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { getMaterialTypeDisplay } from "values/material";

export default function CreateMaterial() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [material, setMaterial] = useState<Material>(empty_material);
    const [material_errors, setMaterialErrors] = useState<MaterialError>(empty_material_errors);

    const handleName = (name: string) => {
        setMaterial({...material, name: name});
        setMaterialErrors({...material_errors, name_error: "", name_has_error: false});
    }

    const handleType = (type: string) => {
        setMaterial({...material, type: type});
    }

    const handleColors = (colors: string) => {
        setMaterial({...material, colors: colors});
        setMaterialErrors({...material_errors, color_error: "", color_has_error: false});
    }

    const handleTaxable = (taxable: boolean) => {
        setMaterial({...material, taxable: taxable});
    }

    const handleDefaultWidth = (width: string) => {
        if(isNaN(Number(width)) === false){
            setMaterial({...material, default_width: Number(width)});
            setMaterialErrors({...material_errors, default_width_error: "", default_width_has_error: false});
        }
        else{
            setMaterialErrors(
                {...material_errors, 
                    default_width_error: "Please enter a valid number",
                    default_width_has_error: true
                });
        }
    }

    const handleDefaultHeight = (height: string) => {
        if(isNaN(Number(height)) === false){
            setMaterial({...material, default_height: Number(height)});
            setMaterialErrors({...material_errors, default_height_error: "", default_height_has_error: false});
        }
        else{
            setMaterialErrors(
                {...material_errors, 
                    default_height_error: "Please enter a valid number",
                    default_height_has_error: true
                });
        }
    }

    const handleDefaultPrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setMaterial({...material, default_price: Number(price)});
            setMaterialErrors({...material_errors, default_price_error: "", default_price_has_error: false});
        }
        else{
            setMaterialErrors(
                {...material_errors, 
                    default_price_error: "Please enter a valid number",
                    default_price_has_error: true
                });
        }
    }

    const handleCreate = () => {
        let error = false;
        let errors: MaterialError = empty_material_errors;

        if(material.name === undefined || material.name === null || material.name === ""){
                errors = {
                    ...errors,
                    name_error: "Please enter a name for this material",
                    name_has_error: true
                }
            error = true;
        }

        if(material.colors === undefined || material.colors === null || material.colors === ""){
            errors = {
                ...errors,
                color_error: "Please enter at least one color",
                color_has_error: true
            }
            error = true;
        }

        if(material_errors.default_height_has_error){
            errors = {
                ...errors,
                default_height_error: "Please enter a valid number",
                default_height_has_error: true
            }
            error = true;
        }

        if(material.default_height === 0){
            errors = {
                ...errors,
                default_height_error: "Height must be greater than 0",
                default_height_has_error: true
            }
            error = true;
        }

        if(material_errors.default_width_has_error){
            errors = {
                ...errors,
                default_width_error: "Please enter a valid number",
                default_width_has_error: true
            }
            error = true;
        }

        if(material.default_width === 0){
            errors = {
                ...errors,
                default_width_error: "Width must be greater than 0",
                default_width_has_error: true
            }
            error = true;
        }

        if(material_errors.default_price_has_error){
            errors = {
                ...errors,
                default_price_error: "Please enter a valid number",
                default_price_has_error: true
            }
            error = true;
        }

        if(material.default_price === 0){
            errors = {
                ...errors,
                default_price_error: "Price must be greater than 0",
                default_price_has_error: true
            }
            error = true;
        }

        if(error){
            setMaterialErrors(errors);
            return;
        }

        dispatch(createMaterial(material));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setMaterial(empty_material);
        setMaterialErrors(empty_material_errors);
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Material</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <FormControl fullWidth>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            autoFocus
                            id="name"
                            label="Name"
                            error={material_errors.name_has_error}
                            onChange={(e) => handleName(e.target.value)} />
                        <FormHelperText error id="name_error">{material_errors.name_error}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            select
                            defaultValue={QUARTZ}
                            id="type"
                            label="Type"
                            onChange={(e) => handleType(e.target.value)}>
                                <MenuItem key={QUARTZ} value={QUARTZ}>
                                    {getMaterialTypeDisplay(QUARTZ)}
                                </MenuItem>
                                <MenuItem key={NATURAL_STONE} value={NATURAL_STONE}>
                                    {getMaterialTypeDisplay(NATURAL_STONE)}
                                </MenuItem>
                                <MenuItem key={ULTRA_COMPACT} value={ULTRA_COMPACT}>
                                    {getMaterialTypeDisplay(ULTRA_COMPACT)}
                                </MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            id="colors"
                            label="Colors (Add a Space)"
                            multiline
                            minRows={3}
                            error={material_errors.color_has_error}
                            onChange={(e) => handleColors(e.target.value)} />
                        <FormHelperText error id="colors_error">{material_errors.color_error}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            sx={textFieldStyle}
                            size="small"
                            variant="outlined"
                            id="default_width"
                            label="Default Width"
                            error={material_errors.default_width_has_error}
                            onChange={(e) => handleDefaultWidth(e.target.value)} />
                        <FormHelperText error id="default_width_error">{material_errors.default_width_error}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            sx={textFieldStyle}
                            size="small"
                            variant="outlined"
                            id="default_height"
                            label="Default Height"
                            error={material_errors.default_height_has_error}
                            onChange={(e) => handleDefaultHeight(e.target.value)} />
                        <FormHelperText error id="default_height_error">{material_errors.default_height_error}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            id="default_price"
                            label="Default Price"
                            error={material_errors.default_price_has_error}
                            onChange={(e) => handleDefaultPrice(e.target.value)} />
                        <FormHelperText error id="default_price_error">{material_errors.default_price_error}</FormHelperText>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        sx={{marginLeft: 1}}
                                        onChange={(e) => handleTaxable(e.target.checked)}
                                        defaultChecked />
                                } 
                                label="Taxable" />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
