import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { PriceList } from "api/types/price";
import { url_prefix } from 'settings';

const price_list_url: string = 'price_list';

export const getPriceLists = (
    name?: string,
    region?: string,
    status?: string,
    units?: string
) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_PRICE_LISTS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${price_list_url}/?`;

        if(name && name !== ""){
            url+=`name=${name}&`;
        }

        if(region && region !== ""){
            url+=`region=${region}&`;
        }

        if(status && status !== ""){
            url+=`status=${status}&`;
        }

        if(units && units !== ""){
            url+=`units=${units}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_PRICE_LISTS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_PRICE_LISTS_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const getPriceList = (id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${price_list_url}/${id}/`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_PRICE_LIST_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            } 
        }
    }
}

export const createPriceList = (price_list: PriceList) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.CREATE_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${price_list_url}/`;

        try
        {
            const {data} = await axios.post(url, price_list, config);

            dispatch({
                type: ActionType.CREATE_PRICE_LIST_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.CREATE_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updatePriceList = (price_list: PriceList, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${price_list_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, price_list, config);

            dispatch({
                type: ActionType.UPDATE_PRICE_LIST_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPDATE_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const deletePriceList = (id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.DELETE_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${price_list_url}/${id}/`;

        try
        {
            const {data} = await axios.delete(url, config);

            dispatch({
                type: ActionType.DELETE_PRICE_LIST_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.DELETE_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}


export const clearPriceList = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CLEAR_PRICE_LIST
        });
    }
}