import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { getMaterialHeightDisplay } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { PORCELAIN } from "values/material";

interface Props {
    saveString: (key: string, value: string) => void
}

export default function ApplicationScope({saveString}: Props){
    return (
        <FormControl fullWidth>
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                select
                defaultValue={CM2}
                id="height"
                label="Material Height"
                onChange={(e) => saveString("material_thickness", e.target.value)}>
                    <MenuItem key={CM2} value={CM2}>
                        {getMaterialHeightDisplay(CM2)}
                    </MenuItem>
                    <MenuItem key={CM3} value={CM3}>
                        {getMaterialHeightDisplay(CM3)}
                    </MenuItem>
                    <MenuItem key={PORCELAIN} value={PORCELAIN}>
                        {getMaterialHeightDisplay(PORCELAIN)}
                    </MenuItem>
            </TextField>
        </FormControl>               
    )
}