import { SplashRange } from "api/types/price";
import PlainRangeItem from "./shared/range_item";

interface Props {
    item: SplashRange
}

export default function SplashRangePlainItem({item}: Props) {
    return(
        <PlainRangeItem 
            any_range={item.any_range ? item.any_range : false}
            rate="$/SQ FT"
            price={item.price ? item.price : 0}
            measurement={item.height ? item.height : 0} />
    )
}
