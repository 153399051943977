import { useState } from "react";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { updateMaterial } from "api/actions/material";
import { Material } from "api/types/material";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { getMaterialHeightDisplay } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { PORCELAIN } from "values/material";

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function EditMaterial({open, setOpen}: Props) {
    const dispatch = useAppDispatch();
    const { material } = useAppSelector((state) => state.material);
    const [new_material, setNewMaterial] = useState<Material>({});

    const handleName = (name: string) => {
        setNewMaterial({...new_material, name: name});
    }

    const handleThickness = (thickness: string) => {
        setNewMaterial({...new_material, thickness: thickness});
    }

    const handleTaxable = (taxable: boolean) => {
        setNewMaterial({...new_material, taxable: taxable});
    }

    const update = () => {
        if(material.id){
            dispatch(updateMaterial(new_material, material.id));
        }
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setNewMaterial({});
    }

    return(
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Material</DialogTitle>
            <DialogContent sx={dialogContentStyle}>
                <FormControl fullWidth>
                    <TextField
                        sx={textFieldStyle}
                        fullWidth
                        size="small"
                        variant="outlined"
                        autoFocus
                        id="name"
                        label="Name"
                        defaultValue={material.name}
                        onChange={(e) => handleName(e.target.value)} />
                    <TextField
                        sx={textFieldStyle}
                        fullWidth
                        size="small"
                        variant="outlined"
                        select
                        defaultValue={material.thickness}
                        id="thickness"
                        label="Thickness"
                        onChange={(e) => handleThickness(e.target.value)}>
                            <MenuItem key={CM2} value={CM2}>
                                {getMaterialHeightDisplay(CM2)}
                            </MenuItem>
                            <MenuItem key={CM3} value={CM3}>
                                {getMaterialHeightDisplay(CM3)}
                            </MenuItem>
                            <MenuItem key={PORCELAIN} value={PORCELAIN}>
                                {getMaterialHeightDisplay(PORCELAIN)}
                            </MenuItem>
                    </TextField>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                sx={{marginLeft: 1}}
                                onChange={(e) => handleTaxable(e.target.checked)}
                                defaultChecked />
                        } 
                        label="Taxable" />
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={update}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}
