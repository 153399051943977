import { useNavigate } from "react-router-dom";
import { useAppSelector } from "api/hooks/apiHook";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UserThumbnail from "./user/thumbnail";
import Login from "components/authentication/login";
import { toolBarStyle } from "styles/style";
import LogoIcon from "static/logo_icon.png";
import { login_url } from "./endpoints";

export default function NavBar() {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.authentication);

    const home_url: string = "";

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, paddingLeft: 0, marginLeft: 0, justifyContent: "center" }}>
            <Toolbar sx={toolBarStyle} disableGutters={true}>
                <img 
                    onClick={() => navigate(home_url, {replace: false})}
                    src={LogoIcon} 
                    width="35"
                    height="35"
                    style={{objectFit: "contain"}}
                    alt="Arris" />
                <Typography color="primary" variant="h4" sx={{marginLeft: 8.5 }}>
                    Arris Staff
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                </Box> 
                {
                    user.id ?
                    <UserThumbnail /> :
                    <Button
                        onClick={() => navigate(login_url, {replace: false})}>
                        Sign In
                    </Button>
                }
                <Login />
            </Toolbar>
        </AppBar>
  );
}
