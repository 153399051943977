import { Region } from "api/types/price";
import { PriceList } from "api/types/price";
import { Splash } from "api/types/price";
import { SplashRange } from "api/types/price";
import { FabricationCharge } from 'api/types/price';
import { InstallationCharge } from 'api/types/price';
import { FinishedEdge } from 'api/types/price';
import { SinkCutout } from "api/types/price";
import { SinkCutoutRange } from "api/types/price";
import { CooktopCutout } from "api/types/price";
import { CooktopCutoutRange } from "api/types/price";
import { ApplianceEdge } from "api/types/price";
import { FaucetCutout } from "api/types/price";
import { OutletCutout } from "api/types/price";
import { ClippedCorner } from "api/types/price";
import { RadiusCorner } from "api/types/price";
import { BumpedOutCorner } from "api/types/price";
import { BumpIn } from "api/types/price";
import { BumpOut } from "api/types/price";
import { BumpInArc } from "api/types/price";
import { BumpOutArc } from "api/types/price";
import { NotchedCorner } from "api/types/price";
import { RecessedDiagonalCorner } from "api/types/price";
import { InsideDiagonalCorner } from "api/types/price";
import { InsideRadiusCorner } from "api/types/price";
import { FullArc } from "api/types/price";
import { StoneCountertopRemoval } from "api/types/price";
import { LaminateCountertopRemoval } from "api/types/price";
import { MaterialToPriceList } from "api/types/price";
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { Action as MaterialAction } from 'api/action_types/material';
import { ActionType as MaterialActionType } from 'api/action_types/material';

interface State {
    available_regions: Region[],
    regions: Region[],
    region: Region,
    price_lists: PriceList[],
    price_list: PriceList,
    materials: MaterialToPriceList[],
    splashes: Splash[],
    splash_ranges: SplashRange[],
    fabrication_charges: FabricationCharge[],
    installation_charges: InstallationCharge[],
    finished_edges: FinishedEdge[],
    sink_cutouts: SinkCutout[],
    sink_cutout_ranges: SinkCutoutRange[],
    cooktop_cutouts: CooktopCutout[],
    cooktop_cutout_ranges: CooktopCutoutRange[],
    appliance_edges: ApplianceEdge[],
    faucet_cutouts: FaucetCutout[],
    outlet_cutouts: OutletCutout[],
    bump_ins: BumpIn[],
    bump_outs: BumpOut[],
    bump_out_arcs: BumpOutArc[],
    bump_in_arcs: BumpInArc[],
    bumped_out_corners: BumpedOutCorner[],
    clipped_corners: ClippedCorner[],
    radius_corners: RadiusCorner[],
    inside_radius_corners: InsideRadiusCorner[],
    inside_diagonal_corners: InsideDiagonalCorner[],
    recessed_diagonal_corners: RecessedDiagonalCorner[],
    full_arcs: FullArc[],
    notched_corners: NotchedCorner[],
    stone_countertop_removals: StoneCountertopRemoval[],
    laminate_countertop_removals: LaminateCountertopRemoval[],
    loading: boolean,
    error: string | null
}

const initialState = {
    available_regions: [],
    regions: [],
    region: {},
    price_lists: [],
    price_list: {},
    materials: [],
    splashes: [],
    splash_ranges: [],
    fabrication_charges: [],
    installation_charges: [],
    finished_edges: [],
    sink_cutouts: [],
    sink_cutout_ranges: [],
    cooktop_cutouts: [],
    cooktop_cutout_ranges: [],
    appliance_edges: [],
    faucet_cutouts: [],
    outlet_cutouts: [],
    bump_ins: [],
    bump_outs: [],
    bump_out_arcs: [],
    bump_in_arcs: [],
    bumped_out_corners: [],
    clipped_corners: [],
    radius_corners: [],
    inside_radius_corners: [],
    inside_diagonal_corners: [],
    recessed_diagonal_corners: [],
    full_arcs: [],
    notched_corners: [],
    stone_countertop_removals: [],
    laminate_countertop_removals: [],
    loading: false,
    error: null
}

export const priceListReducer = (state: State = initialState, action: Action | MaterialAction):State => {
    switch(action.type){
        case ActionType.GET_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_REGION_SUCCESS:
            return {
                ...state,
                region: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.CLEAR_PRICE_LIST:
            return {
                ...state,
                price_list: {},
                materials: [],
                splashes: [],
                splash_ranges: [],
                fabrication_charges: [],
                installation_charges: [],
                finished_edges: [],
                sink_cutouts: [],
                sink_cutout_ranges: [],
                cooktop_cutouts: [],
                cooktop_cutout_ranges: [],
                appliance_edges: [],
                faucet_cutouts: [],
                outlet_cutouts: [],
                bump_ins: [],
                bump_outs: [],
                bump_out_arcs: [],
                bump_in_arcs: [],
                bumped_out_corners: [],
                clipped_corners: [],
                radius_corners: [],
                inside_radius_corners: [],
                inside_diagonal_corners: [],
                recessed_diagonal_corners: [],
                full_arcs: [],
                notched_corners: [],
                stone_countertop_removals: [],
                laminate_countertop_removals: []
            }
        case ActionType.GET_PRICE_LISTS_SUCCESS:
            return {
                ...state,
                price_lists: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_PRICE_LIST_SUCCESS:
            return {
                ...state,
                price_list: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_MATERIAL_TO_PRICE_LISTS_SUCCESS:
            return {
                ...state,
                materials: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_APPLIANCE_EDGES_SUCCESS:
            return {
                ...state,
                appliance_edges: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_BUMP_IN_ARCS_SUCCESS:
            return {
                ...state,
                bump_in_arcs: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_BUMP_INS_SUCCESS:
            return {
                ...state,
                bump_ins: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_BUMP_OUT_ARCS_SUCCESS:
            return {
                ...state,
                bump_out_arcs: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_BUMP_OUTS_SUCCESS:
            return {
                ...state,
                bump_outs: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_BUMPED_OUT_CORNERS_SUCCESS:
            return {
                ...state,
                bumped_out_corners: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_CLIPPED_CORNERS_SUCCESS:
            return {
                ...state,
                clipped_corners: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_COOKTOP_CUTOUTS_SUCCESS:
            return {
                ...state,
                cooktop_cutouts: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_FABRICATION_CHARGES_SUCCESS:
            return {
                ...state,
                fabrication_charges: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_FAUCET_CUTOUTS_SUCCESS:
            return {
                ...state,
                faucet_cutouts: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_FINISHED_EDGES_SUCCESS:
            return {
                ...state,
                finished_edges: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_FULL_ARCS_SUCCESS:
            return {
                ...state,
                full_arcs: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_INSIDE_DIAGONAL_CORNERS_SUCCESS:
            return {
                ...state,
                inside_diagonal_corners: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_INSIDE_RADIUS_CORNERS_SUCCESS:
            return {
                ...state,
                inside_radius_corners: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_INSTALLATION_CHARGES_SUCCESS:
            return {
                ...state,
                installation_charges: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_NOTCHED_CORNERS_SUCCESS:
            return {
                ...state,
                notched_corners: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_OUTLET_CUTOUTS_SUCCESS:
            return {
                ...state,
                outlet_cutouts: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_RADIUS_CORNERS_SUCCESS:
            return {
                ...state,
                radius_corners: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_RECESSED_DIAGONAL_CORNERS_SUCCESS:
            return {
                ...state,
                recessed_diagonal_corners: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_SINK_CUTOUTS_SUCCESS:
            return {
                ...state,
                sink_cutouts: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_SPLASHES_SUCCESS:
            return {
                ...state,
                splashes: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_STONE_COUNTERTOP_REMOVAL_SUCCESS:
            return {
                ...state,
                stone_countertop_removals: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS:
            return {
                ...state,
                laminate_countertop_removals: action.payload,
                loading: false,
                error: ''
            }
        case MaterialActionType.CREATE_MATERIAL_SUCCESS:
            return {
                ...state,
                materials: [...state.materials, action.payload]
            }
        case ActionType.CREATE_MATERIAL_TO_PRICE_LIST_SUCCESS:
            return {
                ...state,
                materials: [...state.materials, ...action.payload],
                loading: false,
                error: ''
            }
        case ActionType.CREATE_REGION_SUCCESS:
            return {
                ...state,
                regions: [...state.regions, action.payload],
                region: action.payload,
                loading: false,
                error: ''
            } 
        case ActionType.CREATE_PRICE_LIST_SUCCESS:
            return {
                ...state,
                price_lists: [...state.price_lists, action.payload],
                price_list: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.CREATE_SPLASH_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                splashes: [...state.splashes.concat(action.payload)],
                error: '',
                loading: false
            }
        case ActionType.CREATE_SPLASH_RANGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                splashes: [...state.splashes.map(splash => splash.id === action.payload.splash ?
                    {...splash, ranges: [...splash.ranges, action.payload]} :
                    splash
                )],
                error: '',
                loading: false
            }
        case ActionType.CREATE_SINK_CUTOUT_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                sink_cutouts: [...state.sink_cutouts.concat(action.payload)]
            }
        case ActionType.CREATE_SINK_CUTOUT_RANGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                sink_cutouts: [...state.sink_cutouts.map(sink => sink.id === action.payload.sink_cutout ?
                    {...sink, ranges: [...sink.ranges, action.payload]} :
                    sink
                )],
                error: '',
                loading: false
            }
        case ActionType.CREATE_COOKTOP_CUTOUT_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                cooktop_cutouts: [...state.cooktop_cutouts.concat(action.payload)]
            }
        case ActionType.CREATE_COOKTOP_CUTOUT_RANGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                cooktop_cutouts: [...state.cooktop_cutouts.map(cooktop => cooktop.id === action.payload.cooktop_cutout ?
                    {...cooktop, ranges: [...cooktop.ranges, action.payload]} :
                    cooktop
                )],
                error: '',
                loading: false
            }
        case ActionType.CREATE_FABRICATION_CHARGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                fabrication_charges: [...state.fabrication_charges, action.payload],
                loading: false,
                error: ''
            }
        case ActionType.CREATE_INSTALLATION_CHARGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                installation_charges: [...state.installation_charges, action.payload],
                loading: false,
                error: ''
            }
        case ActionType.CREATE_FINISHED_EDGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                finished_edges: [...state.finished_edges.concat(action.payload)],
                loading: false,
                error: ''
            } 
        case ActionType.CREATE_APPLIANCE_EDGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                appliance_edges: [...state.appliance_edges, action.payload],
                loading: false,
                error: ''
            }
        case ActionType.CREATE_FAUCET_CUTOUT_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                faucet_cutouts: [...state.faucet_cutouts, action.payload],
                loading: false,
                error: ''
            }
        case ActionType.CREATE_OUTLET_CUTOUT_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                outlet_cutouts: [...state.outlet_cutouts, action.payload],
                loading: false,
                error: ''
            }
        case ActionType.CREATE_NOTCHED_CORNER_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                notched_corners: [...state.notched_corners, action.payload]
            }
        case ActionType.CREATE_CLIPPED_CORNER_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                clipped_corners: [...state.clipped_corners, action.payload]
            }
        case ActionType.CREATE_BUMPED_OUT_CORNER_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                bumped_out_corners: [...state.bumped_out_corners, action.payload]
            }
        case ActionType.CREATE_RADIUS_CORNER_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                radius_corners: [...state.radius_corners.concat(action.payload)]
            }
        case ActionType.CREATE_RADIUS_CORNER_RANGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                radius_corners: [...state.radius_corners.map(item => item.id === action.payload.outside_radius_corner ?
                    {...item, ranges: [...item.ranges, action.payload]} :
                    item
                )],
                error: '',
                loading: false
            }
        case ActionType.CREATE_INSIDE_RADIUS_CORNER_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                inside_radius_corners: [...state.inside_radius_corners.concat(action.payload)]
            }
        case ActionType.CREATE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                inside_radius_corners: [...state.inside_radius_corners.map(item => item.id === action.payload.inside_radius_corner ?
                    {...item, ranges: [...item.ranges, action.payload]} :
                    item
                )],
                error: '',
                loading: false
            }
        case ActionType.CREATE_INSIDE_DIAGONAL_CORNER_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                inside_diagonal_corners: [...state.inside_diagonal_corners, action.payload]
            }
        case ActionType.CREATE_RECESSED_DIAGONAL_CORNER_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                recessed_diagonal_corners: [...state.recessed_diagonal_corners, action.payload]
            }
        case ActionType.CREATE_BUMP_IN_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                bump_ins: [...state.bump_ins, action.payload]
            }
        case ActionType.CREATE_BUMP_IN_ARC_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                bump_in_arcs: [...state.bump_in_arcs, action.payload]
            }
        case ActionType.CREATE_BUMP_OUT_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                bump_outs: [...state.bump_outs, action.payload]
            }
        case ActionType.CREATE_BUMP_OUT_ARC_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                bump_out_arcs: [...state.bump_out_arcs, action.payload]
            }
        case ActionType.CREATE_FULL_ARC_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                full_arcs: [...state.full_arcs, action.payload]
            }
        case ActionType.CREATE_STONE_COUNTERTOP_REMOVAL_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                stone_countertop_removals: [...state.stone_countertop_removals, action.payload]
            }
        case ActionType.CREATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS:
            if(!("id" in action.payload)){return state;}
            return {
                ...state,
                laminate_countertop_removals: [...state.laminate_countertop_removals, action.payload]
            }
        case ActionType.UPDATE_REGION_SUCCESS:
            return {
                ...state,
                region: action.payload,
                regions: [...state.regions.map(region => region.id === action.payload.id ?
                    action.payload :
                    region
                )],
                loading: false,
                error: "There was an error updating the region list"
            } 
        case ActionType.UPDATE_PRICE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                price_list: action.payload
            } 
        case ActionType.UPDATE_FINISHED_EDGE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            }
        case ActionType.UPDATE_STONE_COUNTERTOP_REMOVAL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            }
        case ActionType.UPDATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            }
        case ActionType.UPDATE_LOCAL_MATERIAL_TO_PRICE_LIST:
            return {
                ...state,
                materials: [...state.materials.map(material => material.id === action.payload.id ? 
                    action.payload :
                    material
                )]
            }
        case ActionType.UPDATE_LOCAL_INSTALLATION_CHARGE:
            return {
                ...state,
                installation_charges: [...state.installation_charges.map(item => item.id !== action.payload.id ? item : action.payload)]
            }   
        case ActionType.UPDATE_LOCAL_APPLIANCE_EDGE:
            return {
                ...state,
                appliance_edges: [...state.appliance_edges.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_BUMP_IN_ARC:
            return {
                ...state,
                bump_in_arcs: [...state.bump_in_arcs.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_BUMP_IN:
            return {
                ...state,
                bump_ins: [...state.bump_ins.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_BUMP_OUT_ARC:
            return {
                ...state,
                bump_out_arcs: [...state.bump_out_arcs.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_BUMP_OUT:
            return {
                ...state,
                bump_outs: [...state.bump_outs.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_BUMP_OUT_CORNER:
            return {
                ...state,
                bumped_out_corners: [...state.bumped_out_corners.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_CLIPPED_CORNER:
            return {
                ...state,
                clipped_corners: [...state.clipped_corners.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_FABRICATION_CHARGE:
            return {
                ...state,
                fabrication_charges: [...state.fabrication_charges.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_FAUCET_CUTOUT:
            return {
                ...state,
                faucet_cutouts: [...state.faucet_cutouts.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_FINISHED_EDGE:
            return {
                ...state,
                finished_edges: [...state.finished_edges.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_RECESSED_DIAGONAL_CORNER:
            return {
                ...state,
                recessed_diagonal_corners: [
                    ...state.recessed_diagonal_corners.map(item => item.id !== action.payload.id ? item : action.payload)
                ]
            }
        case ActionType.UPDATE_LOCAL_FULL_ARC:
            return {
                ...state,
                full_arcs: [...state.full_arcs.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_NOTCHED_CORNER:
            return {
                ...state,
                notched_corners: [...state.notched_corners.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_OUTLET_CUTOUT:
            return {
                ...state,
                outlet_cutouts: [...state.outlet_cutouts.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_SPLASH:
            return {
                ...state,
                splashes: [...state.splashes.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_SPLASH_RANGE:
            return {
                ...state,
                splashes: [...state.splashes.map(splash => splash.id === action.payload.splash ?
                    {...splash, ranges: [...splash.ranges.map(range => range.id === action.payload.id ?
                        action.payload :
                        range
                    )]} :
                    splash
                )]
            }
        case ActionType.UPDATE_LOCAL_COOKTOP_CUTOUT:
            return {
                ...state,
                cooktop_cutouts: [...state.cooktop_cutouts.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_COOKTOP_CUTOUT_RANGE:
            return {
                ...state,
                cooktop_cutouts: [...state.cooktop_cutouts.map(item => item.id === action.payload.cooktop_cutout ?
                    {...item, ranges: [...item.ranges.map(range => range.id === action.payload.id ? action.payload : range)]} :
                    item
                )]
            }
        case ActionType.UPDATE_LOCAL_RADIUS_CORNER:
            return {
                ...state,
                radius_corners: [...state.radius_corners.map(item => item.id === action.payload.id ?
                    action.payload :
                    item
                )]
            }
        case ActionType.UPDATE_LOCAL_RADIUS_CORNER_RANGE:
            return {
                ...state,
                radius_corners: [...state.radius_corners.map(item => item.id === action.payload.outside_radius_corner ?
                    {...item, ranges: [...item.ranges.map(range => range.id === action.payload.id ? 
                        action.payload :
                        range
                    )]} :
                    item
                )]
            }
        case ActionType.UPDATE_LOCAL_INSIDE_RADIUS_CORNER:
            return {
                ...state,
                inside_radius_corners: [...state.inside_radius_corners.map(item => item.id === action.payload.id ?
                    action.payload :
                    item
                )]
            }
        case ActionType.UPDATE_LOCAL_INSIDE_RADIUS_CORNER_RANGE:
            return {
                ...state,
                inside_radius_corners: [...state.inside_radius_corners.map(item => item.id === action.payload.inside_radius_corner ?
                    {...item, ranges: [...item.ranges.map(range => range.id === action.payload.id ? 
                        action.payload :
                        range
                    )]} :
                    item
                )],
            }
        case ActionType.UPDATE_LOCAL_SINK_CUTOUT:
            return {
                ...state,
                sink_cutouts: [...state.sink_cutouts.map(item => item.id !== action.payload.id ? item : action.payload)]
            }
        case ActionType.UPDATE_LOCAL_SINK_CUTOUT_RANGE:
            return {
                ...state,
                sink_cutouts: [...state.sink_cutouts.map(item => item.id === action.payload.sink_cutout ?
                    {...item, ranges: [...item.ranges.map(range => range.id === action.payload.id ? action.payload : range)]} :
                    item
                )]
            }
        case ActionType.UPDATE_LOCAL_STONE_COUNTERTOP_REMOVAL:
            return {
                ...state,
                stone_countertop_removals: [...state.stone_countertop_removals.map(item => item.id === action.payload.id ?
                    action.payload :
                    item
                )]
            }
        case ActionType.UPDATE_LOCAL_LAMINATE_COUNTERTOP_REMOVAL:
            return {
                ...state,
                laminate_countertop_removals: [...state.laminate_countertop_removals.map(item => item.id === action.payload.id ?
                    action.payload :
                    item
                )]
            }
        case ActionType.DELETE_SPLASH_RANGE_SUCCESS:
            return {
                ...state,
                splashes: [...state.splashes.map(splash => splash.id === action.payload.splash ?
                    {...splash, ranges: [...splash.ranges.filter(splash_range => splash_range.id !== action.payload.id)]} :
                    splash
                )],
                error: '',
                loading: false
            }
        case ActionType.DELETE_SINK_CUTOUT_RANGE_SUCCESS:
            return {
                ...state,
                sink_cutouts: [...state.sink_cutouts.map(sink => sink.id === action.payload.sink_cutout ?
                    {...sink, ranges: [...sink.ranges.filter(sink_range => sink_range.id !== action.payload.id)]} :
                    sink
                )],
                error: '',
                loading: false
            }
        case ActionType.DELETE_COOKTOP_CUTOUT_RANGE_SUCCESS:
            return {
                ...state,
                cooktop_cutouts: [...state.cooktop_cutouts.map(cooktop => cooktop.id === action.payload.cooktop_cutout ?
                    {...cooktop, ranges: [...cooktop.ranges.filter(cooktop_range => cooktop_range.id !== action.payload.id)]} :
                    cooktop
                )],
                error: '',
                loading: false
            }
        case ActionType.DELETE_RADIUS_CORNER_RANGE_SUCCESS:
            return {
                ...state,
                radius_corners: [...state.radius_corners.map(item => item.id === action.payload.outside_radius_corner ?
                    {...item, ranges: [...item.ranges.filter(range => range.id !== action.payload.id)]} :
                    item
                )],
                error: '',
                loading: false
            }
        case ActionType.DELETE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS:
            return {
                ...state,
                inside_radius_corners: [...state.inside_radius_corners.map(item => item.id === action.payload.inside_radius_corner ?
                    {...item, ranges: [...item.ranges.filter(range => range.id !== action.payload.id)]} :
                    item
                )],
                error: '',
                loading: false
            }
        case ActionType.DELETE_REGION_SUCCESS:
            return {
                ...state,
                regions: [...state.regions.filter(item => item.id !== action.payload.id)],
                loading: false
            }     
        case ActionType.DELETE_PRICE_LIST_SUCCESS:
            return {
                ...state,
                loading: false
            }     
        case ActionType.DELETE_FINISHED_EDGE_SUCCESS:
            return {
                ...state,
                loading: false
            }  
        case ActionType.DELETE_MATERIAL_TO_PRICE_LIST:
            return {
                ...state,
                loading: false,
                error: '',
                materials: [...state.materials.filter(item => item.id !== action.payload.id)]
            }
        case ActionType.GET_REGIONS_PENDING:
        case ActionType.GET_REGION_PENDING:
        case ActionType.GET_PRICE_LISTS_PENDING:
        case ActionType.GET_PRICE_LIST_PENDING:
        case MaterialActionType.CREATE_MATERIAL_PENDING:
        case ActionType.CREATE_MATERIAL_TO_PRICE_LIST_PENDING:
        case ActionType.CREATE_REGION_PENDING:
        case ActionType.CREATE_PRICE_LIST_PENDING:
        case ActionType.CREATE_SPLASH_PENDING:
        case ActionType.CREATE_SPLASH_RANGE_PENDING:
        case ActionType.CREATE_SINK_CUTOUT_PENDING:
        case ActionType.CREATE_SINK_CUTOUT_RANGE_PENDING:
        case ActionType.CREATE_COOKTOP_CUTOUT_PENDING:
        case ActionType.CREATE_COOKTOP_CUTOUT_RANGE_PENDING:
        case ActionType.CREATE_FABRICATION_CHARGE_PENDING:
        case ActionType.CREATE_INSTALLATION_CHARGE_PENDING:
        case ActionType.CREATE_FINISHED_EDGE_PENDING:
        case ActionType.CREATE_APPLIANCE_EDGE_PENDING:
        case ActionType.CREATE_FAUCET_CUTOUT_PENDING:
        case ActionType.CREATE_OUTLET_CUTOUT_PENDING:
        case ActionType.CREATE_NOTCHED_CORNER_PENDING:
        case ActionType.CREATE_CLIPPED_CORNER_PENDING:
        case ActionType.CREATE_BUMPED_OUT_CORNER_PENDING:
        case ActionType.CREATE_RADIUS_CORNER_PENDING:
        case ActionType.CREATE_RADIUS_CORNER_RANGE_PENDING:
        case ActionType.CREATE_INSIDE_RADIUS_CORNER_PENDING:
        case ActionType.CREATE_INSIDE_RADIUS_CORNER_RANGE_PENDING:
        case ActionType.CREATE_INSIDE_DIAGONAL_CORNER_PENDING:
        case ActionType.CREATE_RECESSED_DIAGONAL_CORNER_PENDING:
        case ActionType.CREATE_BUMP_IN_PENDING:
        case ActionType.CREATE_BUMP_IN_ARC_PENDING:
        case ActionType.CREATE_BUMP_OUT_PENDING:
        case ActionType.CREATE_BUMP_OUT_ARC_PENDING:
        case ActionType.CREATE_FULL_ARC_PENDING:
        case ActionType.UPDATE_REGION_PENDING:
        case ActionType.UPDATE_PRICE_LIST_PENDING:
        case ActionType.UPDATE_FINISHED_EDGE_PENDING:
        case ActionType.DELETE_SPLASH_RANGE_PENDING:
        case ActionType.DELETE_SINK_CUTOUT_RANGE_PENDING:
        case ActionType.DELETE_COOKTOP_CUTOUT_RANGE_PENDING:
        case ActionType.DELETE_RADIUS_CORNER_RANGE_PENDING:
        case ActionType.DELETE_INSIDE_RADIUS_CORNER_RANGE_PENDING:
        case ActionType.DELETE_REGION_PENDING:
        case ActionType.DELETE_PRICE_LIST_PENDING:
        case ActionType.DELETE_FINISHED_EDGE_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.GET_REGIONS_FAIL:
        case ActionType.GET_REGION_FAIL:
        case ActionType.GET_PRICE_LISTS_FAIL:
        case ActionType.GET_PRICE_LIST_FAIL:
        case MaterialActionType.CREATE_MATERIAL_FAIL:
        case ActionType.CREATE_MATERIAL_TO_PRICE_LIST_FAIL:
        case ActionType.CREATE_REGION_FAIL:
        case ActionType.CREATE_PRICE_LIST_FAIL:
        case ActionType.CREATE_SPLASH_FAIL:
        case ActionType.CREATE_SPLASH_RANGE_FAIL:
        case ActionType.CREATE_SINK_CUTOUT_FAIL:
        case ActionType.CREATE_SINK_CUTOUT_RANGE_FAIL:
        case ActionType.CREATE_COOKTOP_CUTOUT_FAIL:
        case ActionType.CREATE_COOKTOP_CUTOUT_RANGE_FAIL:
        case ActionType.CREATE_FABRICATION_CHARGE_FAIL:
        case ActionType.CREATE_INSTALLATION_CHARGE_FAIL:
        case ActionType.CREATE_FINISHED_EDGE_FAIL:
        case ActionType.CREATE_APPLIANCE_EDGE_FAIL:
        case ActionType.CREATE_FAUCET_CUTOUT_FAIL:
        case ActionType.CREATE_OUTLET_CUTOUT_FAIL:
        case ActionType.CREATE_NOTCHED_CORNER_FAIL:
        case ActionType.CREATE_CLIPPED_CORNER_FAIL:
        case ActionType.CREATE_BUMPED_OUT_CORNER_FAIL:
        case ActionType.CREATE_RADIUS_CORNER_FAIL:
        case ActionType.CREATE_RADIUS_CORNER_RANGE_FAIL:
        case ActionType.CREATE_INSIDE_RADIUS_CORNER_FAIL:
        case ActionType.CREATE_INSIDE_RADIUS_CORNER_RANGE_FAIL:
        case ActionType.CREATE_INSIDE_DIAGONAL_CORNER_FAIL:
        case ActionType.CREATE_RECESSED_DIAGONAL_CORNER_FAIL:
        case ActionType.CREATE_BUMP_IN_FAIL:
        case ActionType.CREATE_BUMP_IN_ARC_FAIL:
        case ActionType.CREATE_BUMP_OUT_FAIL:
        case ActionType.CREATE_BUMP_OUT_ARC_FAIL:
        case ActionType.CREATE_FULL_ARC_FAIL:
        case ActionType.UPDATE_REGION_FAIL:
        case ActionType.UPDATE_PRICE_LIST_FAIL:
        case ActionType.UPDATE_FINISHED_EDGE_FAIL:
        case ActionType.DELETE_SPLASH_RANGE_FAIL:
        case ActionType.DELETE_SINK_CUTOUT_RANGE_FAIL:
        case ActionType.DELETE_COOKTOP_CUTOUT_RANGE_FAIL:
        case ActionType.DELETE_RADIUS_CORNER_RANGE_FAIL:
        case ActionType.DELETE_INSIDE_RADIUS_CORNER_RANGE_FAIL:
        case ActionType.DELETE_REGION_FAIL:
        case ActionType.DELETE_PRICE_LIST_FAIL:
        case ActionType.DELETE_FINISHED_EDGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }  
        default:
            return state;
    }
}



