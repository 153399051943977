import { Fragment } from 'react';
import { useState } from 'react';
import { useAppDispatch } from 'api/hooks/apiHook';
import { uploadMaterialFile } from "api/actions/material_import";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { primary_color } from 'styles/style';
import { white_color } from 'styles/style';
import { dialogContentStyle } from 'styles/style';
import { listIndentStyle } from 'styles/style';

export default function CreateUpload() {
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);

    const handleImage = (e: any) => {
        setFile(e.target.files[0]);
    }

    const submit = () => {
        dispatch(uploadMaterialFile(file));
    }

    return(
        <Stack direction="row" alignItems="center">
            <Tooltip title="Upload Instructions">
                <IconButton onClick={() => setOpen(true)} sx={{marginRight: 1}}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <input
                type="file"
                name="image"
                onChange={(e) => handleImage(e)}/>
            <Button onClick={submit}>
                Submit
            </Button>
            <Dialog 
                maxWidth={"md"}
                open={open}>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Upload Instructions</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <Stack sx={{marginTop: 0.5}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold', marginBottom: 0.5}}>
                            Upload file can be a csv or excel file
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight: 'bold', marginBottom: 0.5}}>
                            1. The first content row needs to have the Headers for the Material, they can be in any order.
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            <b>a.</b> Material: Required: This is the name of the material. The header should be "Name", "Material" or "M"
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            <b>b.</b> Type: Required: This is the type of material ie Quartz. The header should be "Type"
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            <b>c.</b> Taxable: Optional: This is a yes or no value. Default is Yes. Options are "True", "T", "Yes" or "y" for Yes. "False", "F", "No" or "N" for no.
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            <b>d.</b> Enabled: Optional: This is a yes or no value. Default is No. Options are "True", "T", "Yes" or "y" for Yes. "False", "F", "No" or "N" for no.
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                            2. The row below should have the corresponding values to the headers ie. the name and type
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                            3. There can be any number of empty rows, but the next row with content should be the Headers for the Colors, they can be in any order.
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            a. Color: Required: This is the name of the color. The header should be "Name", "Color", "C"
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            b. Width: Required: This is the width of the color slab. The values should be a number. The header should be "Width"
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            c. Height: Required: This is the height of the color slab. The values should be a number. The header should be "Height"
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            d. Slab Price: Required: This is the price of a single slab. The values should be a number. The header should be "Price" or "Slab Price"
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            e. Enabled: Optional: This is a yes or no value. Default is No. Header should be "True", "T", "Yes" or "y" for Yes. "False", "F", "No" or "N" for no.
                        </Typography>
                        <Typography variant="body1" sx={listIndentStyle}>
                            f. Markup: Optional: this is a number value. Default is 15.00 (15%). Header should be "Markup", "15%", "Markup %", "Markup%"
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                            4. Any number of colors can be listed below with values corresponding to the headers
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                            5. Multiple material can be in one excel file on separate tabs
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                            6. Columns can have other headers for your convenience, they will be ignored by the importer
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}
