import { Material } from "api/types/material";
import { Color } from "api/types/material";

export enum ActionType {
    GET_MATERIALS_PENDING = "GET_MATERIAL_PENDING",
    GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS",
    GET_MATERIALS_FAIL = "GET_MATERIALS_FAIL",
    GET_MATERIAL_PENDING="GET_MATERIAL_PENDING",
    GET_MATERIAL_SUCCESS="GET_MATERIAL_SUCCESS",
    GET_MATERIAL_FAIL="GET_MATERIAL_FAIL",
    CREATE_MATERIAL_PENDING = "CREATE_MATERIAL_PENDING",
    CREATE_MATERIAL_SUCCESS = "CREATE_MATERIAL_SUCCESS",
    CREATE_MATERIAL_FAIL = "CREATE_MATERIAL_FAIL",
    UPDATE_MATERIAL_PENDING = "UPDATE_MATERIAL_PENDING",
    UPDATE_MATERIAL_SUCCESS = "UPDATE_MATERIAL_SUCCESS",
    UPDATE_MATERIAL_FAIL = "UPDATE_MATERIAL_FAIL",
    DELETE_MATERIAL_PENDING = "DELETE_MATERIAL_PENDING",
    DELETE_MATERIAL_SUCCESS = "DELETE_MATERIAL_SUCCESS",
    DELETE_MATERIAL_FAIL = "DELETE_MATERIAL_FAIL",
    CREATE_COLOR_PENDING = "CREATE_COLOR_PENDING",
    CREATE_COLOR_SUCCESS = "CREATE_COLOR_SUCCESS",
    CREATE_COLOR_FAIL = "CREATE_COLOR_FAIL",
    UPDATE_COLOR_PENDING = "UPDATE_COLOR_PENDING",
    UPDATE_COLOR_SUCCESS = "UPDATE_COLOR_SUCCESS",
    UPDATE_COLOR_FAIL = "UPDATE_COLOR_FAIL",
    DELETE_COLOR_PENDING = "DELETE_COLOR_PENDING",
    DELETE_COLOR_SUCCESS = "DELETE_COLOR_SUCCESS",
    DELETE_COLOR_FAIL = "DELETE_COLOR_FAIL",
    SET_MATERIAL = "SET_MATERIAL",
    UPDATE_LOCAL_COLOR = "UPDATE_LOCAL_COLOR",
    CLEAR_MATERIALS="CLEAR_MATERIALS"
}

interface getMaterialsPending {
    type: ActionType.GET_MATERIALS_PENDING
}

interface getMaterialsSuccess {
    type: ActionType.GET_MATERIALS_SUCCESS,
    payload: Material[]
}

interface getMaterialsFail {
    type: ActionType.GET_MATERIALS_FAIL,
    payload: string
}

interface getMaterialPending {
    type: ActionType.GET_MATERIAL_PENDING
}

interface getMaterialSuccess {
    type: ActionType.GET_MATERIAL_SUCCESS,
    payload: Material
}

interface getMaterialFail {
    type: ActionType.GET_MATERIAL_FAIL,
    payload: string
}

interface createMaterialPending {
    type: ActionType.CREATE_MATERIAL_PENDING
}

interface createMaterialSuccess {
    type: ActionType.CREATE_MATERIAL_SUCCESS,
    payload: Material
}

interface createMaterialFail {
    type: ActionType.CREATE_MATERIAL_FAIL,
    payload: string
}

interface updateMaterialPending {
    type: ActionType.UPDATE_MATERIAL_PENDING,
    payload: Material
}

interface updateMaterialSuccess {
    type: ActionType.UPDATE_MATERIAL_SUCCESS,
    payload: Material
}

interface updateMaterialFail {
    type: ActionType.UPDATE_MATERIAL_FAIL,
    payload: string
}

interface deleteMaterialPending {
    type: ActionType.DELETE_MATERIAL_PENDING
}

interface deleteMaterialSuccess {
    type: ActionType.DELETE_MATERIAL_SUCCESS,
    payload: Material
}

interface deleteMaterialFail {
    type: ActionType.DELETE_MATERIAL_FAIL,
    payload: string
}

interface createColorPending {
    type: ActionType.CREATE_COLOR_PENDING
}

interface createColorSuccess {
    type: ActionType.CREATE_COLOR_SUCCESS,
    payload: Color
}

interface createColorFail {
    type: ActionType.CREATE_COLOR_FAIL,
    payload: string
}

interface updateColorPending {
    type: ActionType.UPDATE_COLOR_PENDING,
    payload: Color
}

interface updateColorSuccess {
    type: ActionType.UPDATE_COLOR_SUCCESS,
    payload: Color
}

interface updateColorFail {
    type: ActionType.UPDATE_COLOR_FAIL,
    payload: string
}

interface deleteColorPending {
    type: ActionType.DELETE_COLOR_PENDING
}

interface deleteColorSuccess {
    type: ActionType.DELETE_COLOR_SUCCESS,
    payload: Color
}

interface deleteColorFail {
    type: ActionType.DELETE_COLOR_FAIL,
    payload: string
}

interface setMaterial {
    type: ActionType.SET_MATERIAL,
    payload: Material
}

interface updateLocalColor {
    type: ActionType.UPDATE_LOCAL_COLOR,
    payload: Color
}

interface clearMaterials {
    type: ActionType.CLEAR_MATERIALS
}

export type Action = 
    getMaterialsPending |
    getMaterialsSuccess |
    getMaterialsFail |
    getMaterialPending |
    getMaterialSuccess |
    getMaterialFail |
    createMaterialPending |
    createMaterialSuccess |
    createMaterialFail |
    updateMaterialPending |
    updateMaterialSuccess |
    updateMaterialFail |
    deleteMaterialPending |
    deleteMaterialSuccess |
    deleteMaterialFail |
    createColorPending |
    createColorSuccess |
    createColorFail |
    updateColorPending |
    updateColorSuccess |
    updateColorFail |
    deleteColorPending |
    deleteColorSuccess |
    deleteColorFail |
    setMaterial |
    updateLocalColor |
    clearMaterials;