import { Material } from "api/types/material";
import { MaterialError } from "api/types/price";
import { QUARTZ } from "values/material";

export const empty_material: Material = {
    name: "",
    type: QUARTZ,
    taxable: true,
    default_width: 0,
    default_height: 0,
    default_price: 0
} 

export const empty_material_errors: MaterialError = {
    name_error: "",
    name_has_error: false,
    color_error: "",
    color_has_error: false,
    default_width_error: "",
    default_width_has_error: false,
    default_height_error: "",
    default_height_has_error: false,
    default_price_error: "",
    default_price_has_error: false
}