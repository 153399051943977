import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Provider } from "react-redux";
import { store } from "api/store";
import { createTheme} from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MainRouter from "components/navigation/router";
import { white_color } from "styles/style";
import { primary_color } from "styles/style";
import { secondary_color } from "styles/style";
import { error_color } from "styles/style";
import { text_primary } from "styles/style";
import { text_secondary } from "styles/style";
import { STRIPE_PUBLIC_KEY } from "settings";
import "./App.css";

export default function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: primary_color
            },
                secondary: {
                main: secondary_color
            },
            error: {
                main: error_color
            },
            text: {
                primary: text_primary
            }
        },
        typography: {
            fontFamily: [
                "Roboto"
            ].join(","),
            h1: {
                fontSize: 48,
                fontFamily: "Roboto",
                paddingTop: 15,
                paddingBottom: 15,
                marginBottom: 30,
                marginTop: 30,
                color: text_primary
            },
            h2: {
                fontSize: 36,
                fontFamily: "Roboto"
            },
            h3: {
                fontSize: 24,
                fontFamily: "Roboto",
                textTransform: "uppercase",
                color: text_primary
            },
            h4: {
                fontSize: 17,
                fontFamily: "Roboto",
                color: primary_color,
                textTransform: "uppercase",
                fontWeight: "bold"
            },
            h5: {
                fontSize: 16,
                fontWeight: "bold",
                color: text_primary
            },
            h6: {
                fontSize: 16,
                fontWeight: "bold",
                color: white_color
            },
            body1: {
                fontFamily: "Roboto",
                fontSize: 16,
                color: text_primary,
                whiteSpace: "pre-line",
                wordWrap: "break-word"
            },
            body2: {
                fontFamily: "Roboto",
                fontSize: 14,
                color: text_secondary,
                whiteSpace: "pre-line",
                wordWrap: "break-word"
            }
        },
        components: {
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        paddingTop: 10,
                        paddingBottom: 14,
                        paddingLeft: 24
                    },
                  },
                defaultProps: {
                    height: 18,
                }
            },
            MuiTextField: {
                defaultProps: {
                    InputProps: {
                        fullWidth: true,
                        size: "small",
                        style: {
                            fontSize: 14
                        }
                    },
                    InputLabelProps: {
                        style: {
                            fontSize: 14
                        }
                    }
                }
            },
            MuiMenuItem: {
                defaultProps: {
                    style: {
                        fontSize: 14
                    }
                }
            }
        }
    });

    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

    return(
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Elements stripe={stripePromise}>
                <Box>
                    <MainRouter />
                </Box>
              </Elements>
          </ThemeProvider>
        </Provider>
    )
}
