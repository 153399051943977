import { useAppDispatch } from "api/hooks/apiHook";
import { deleteMaterialToPriceList } from "api/actions/price/material_to_price_list";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Name from "../shared/name";
import { MaterialToPriceList } from "api/types/price";
import { getRowSecondStyle } from 'values/style';
import { getMaterialHeightDisplay } from "values/material";

interface Props {
    index: number,
    item: MaterialToPriceList
}

export default function MaterialToPriceListEditableItem({index, item}: Props) {
    const dispatch = useAppDispatch();

    return(
        <Grid container item alignItems="center" sx={[{padding: 1}, getRowSecondStyle(index)]}>
            <Name 
                bold={false}
                name={item.material_data && item.material_data.name ? item.material_data.name : ""} 
                width={6} />
 
            <Grid item xs={4}>
                <Typography variant="body2">
                    {getMaterialHeightDisplay(item.material_data ? item.material_data.thickness : "")}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton 
                        onClick={() => dispatch(deleteMaterialToPriceList(item.id ? item.id : 0))}
                        color="error" 
                        sx={{marginRight: 0.5, height: 20, width: 20}}
                        size="small">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}
