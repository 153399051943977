import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { UserGeo } from "api/types/authentication";
import { getRegions } from "api/actions/price";
import { updateAccount } from "api/actions/account";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import {SelectField} from "components/universal/textfield";
import {NumberField} from "components/universal/textfield";
import SectionHeader from "components/universal/section_header";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { getLatLng } from "react-google-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { JSONIsEmpty } from "functions/value";
import { primary_color } from "styles/style";
import { secondary_color } from "styles/style";
import { GOOGLE_API_KEY } from "settings";

export default function AccountGeo() {
    const dispatch = useAppDispatch();
    const { account } = useAppSelector((state) => state.account);
    const { regions } = useAppSelector((state) => state.price);
    const [geo, setGeo] = useState<UserGeo>({});

    useEffect(() =>{
        dispatch(getRegions(false));
    }, []);

    const setMapValue = (e: any) => {
        geocodeByAddress(e.label)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            setGeo({
                ...geo,
                geo_found: true,
                lng:  Math.round(lng * 100000) / 100000,
                lat:  Math.round(lat * 100000) / 100000,
                geo_address: e.label
            });
        });
    }

    const update = (key: string, value: string | number) => {
        setGeo({...geo, [key]: value});
    }

    const save = () => {
        dispatch(updateAccount(geo, account.id));
        setGeo({});
    }

    const menu_items = regions.map(region => {
        return (
            {
                value: region.id,
                key: region.id.toString(),
                label: region.name
            }
        )
    });

    return (
        <Stack sx={{marginTop: 4}}>
            <SectionHeader text="Geo Location" variant="outlined" />
            <Stack direction="row" alignItems="center" spacing={1} sx={{paddingTop: 1, paddingBottom: 1}}>
                <SelectField init_select={account.region} data_key="region" label="Region" items={menu_items} update={update} />
                <NumberField init_value={account.mileage_rate} data_key="mileage_rate" label="Mileage Rate" update={update} />
                <NumberField init_value={account.radius} data_key="radius" label="Radius" update={update} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={3} sx={{paddingTop: 1, paddingBottom: 1}}>
                <Typography variant="body2">
                    Lat: {account.lat}
                </Typography>
                <Typography variant="body2">
                    Lng: {account.lng}
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled
                            sx={{height: 16, width: 16, marginRight: 1}}
                            checked={account.geo_found} />
                    } 
                    label="Geo Location Found" />
            </Stack>
            {
                GOOGLE_API_KEY && GOOGLE_API_KEY !== "" ?
                <GooglePlacesAutocomplete
                    apiOptions={{
                        libraries: ["places"],
                        region: "ca"
                    }}
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['ca'],
                        }
                    }}
                    selectProps={{
                        placeholder: account.geo_address,
                        onChange: (place) => setMapValue(place),
                    }}/> :
                null
            }
            
            <Button 
                size="small"
                sx={{marginTop: 2, backgroundColor: JSONIsEmpty(geo) ? primary_color : secondary_color}}
                onClick={save}
                variant="contained">
                Save Geo Location
            </Button>
        </Stack>
    )
}