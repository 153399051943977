import React from "react";
import { useState } from "react";
import { createRegion } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { Region } from "api/types/price";
import { RegionErrors } from "api/types/price";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { NEAREST_CENT } from "values/values";
import { getBoolFromString } from "functions/value";

export default function CreateRegion() {
    const dispatch = useAppDispatch();

    const empty_region: Region = {
        name: "",
        default_tax_rate_name: "HST",
        default_tax_rate: 0,
        default_payment_terms: "",
        has_second_tax: false,
        second_tax_rate_name: "HST",
        second_tax_rate: 0,
        quote_expiration_days: 30,
        round_quotes: NEAREST_CENT
    }

    const empty_region_errors: RegionErrors = {
        name_error: "",
        name_has_error: false,
        default_tax_rate_error: "",
        default_tax_rate_has_error: false,
        second_tax_rate_error: "",
        second_tax_rate_has_error: false,
        quote_expiration_days_error: "",
        quote_expiration_days_has_error: false
    }

    const [open, setOpen] = useState<boolean>(false);
    const [region, setRegion] = useState<Region>(empty_region);
    const [region_errors, setRegionErrors] = useState<RegionErrors>(empty_region_errors);

    const saveValue = (key: string, value: string | boolean) => {
        const update_region: Region = {...region, [key]: value};
        setRegion(update_region);
        setRegionErrors({
            ...region_errors,
            [`${key}_error`]: "",
            [`${key}_has_error`]: false
        });
    }

    const handleCreate = () => {
        let error = false;
        let errors: RegionErrors = empty_region_errors;

        if(region.name === undefined || region.name === null || region.name === ""){
            errors = {
                ...errors,
                name_error: "Please enter a name for this region",
                name_has_error: true
            }
            error = true;
        }

        if(region.default_tax_rate === null || region.default_tax_rate === undefined || isNaN(Number(region.default_tax_rate))){
            errors = {
                ...errors,
                default_tax_rate_error: "Please enter a valid number",
                default_tax_rate_has_error: true
            }
            error = true;
        }

        if(region.quote_expiration_days === null || region.quote_expiration_days === undefined || isNaN(Number(region.quote_expiration_days))){
            errors = {
                ...errors,
                quote_expiration_days_error: "Please enter a valid number",
                quote_expiration_days_has_error: true
            }
            error = true;
        }

        if(error){
            setRegionErrors(errors);
            return;
        }

        dispatch(createRegion(region));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setRegion(empty_region);
        setRegionErrors(empty_region_errors);
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Region</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <FormControl fullWidth>
                        <FormHelperText error id="name_error">{region_errors.name_error}</FormHelperText>
                        <TextField
                            fullWidth
                            sx={textFieldStyle}
                            size="small"
                            variant="outlined"
                            autoFocus
                            id="name"
                            label="Name"
                            error={region_errors.name_has_error}
                            onChange={(e) => saveValue("name", e.target.value)} />
                        <TextField
                            fullWidth
                            sx={textFieldStyle}
                            select
                            size="small"
                            variant="outlined"
                            defaultValue="HST"
                            id="default_tax_rate_name"
                            label="Default Tax Rate Name"
                            onChange={(e) => saveValue("default_tax_rate_name", e.target.value)}>
                            <MenuItem key="HST" value="HST">
                                HST
                            </MenuItem>
                            <MenuItem key="GST" value="GST">
                                GST
                            </MenuItem>
                            <MenuItem key="PST" value="PST">
                                PST
                            </MenuItem>
                        </TextField>
                        <FormHelperText error id="tax_rate_error">{region_errors.default_tax_rate_error}</FormHelperText>
                        <TextField
                            fullWidth
                            sx={textFieldStyle}
                            type="number"
                            size="small"
                            variant="outlined"
                            defaultValue={0.00}
                            id="default_tax_rate"
                            label="Default Tax Rate"
                            error={region_errors.default_tax_rate_has_error}
                            onChange={(e) => saveValue("default_tax_rate", e.target.value)} />
                        <TextField
                            fullWidth
                            sx={textFieldStyle}
                            select
                            size="small"
                            variant="outlined"
                            defaultValue="No"
                            id="has_second_tax"
                            label="Use Second Tax"
                            onChange={(e) => saveValue("has_second_tax", getBoolFromString(e.target.value))}>
                            <MenuItem key="No" value="No">
                                No
                            </MenuItem>
                            <MenuItem key="Yes" value="Yes">
                                Yes
                            </MenuItem>
                        </TextField>
                        <TextField
                            fullWidth
                            sx={textFieldStyle}
                            select
                            size="small"
                            variant="outlined"
                            defaultValue="HST"
                            id="second_tax_rate_name"
                            label="Second Tax Rate Name"
                            onChange={(e) => saveValue("second_tax_rate_name", e.target.value)}>
                            <MenuItem key="HST" value="HST">
                                HST
                            </MenuItem>
                            <MenuItem key="GST" value="GST">
                                GST
                            </MenuItem>
                            <MenuItem key="PST" value="PST">
                                PST
                            </MenuItem>
                        </TextField>
                        <FormHelperText error id="second_tax_rate_error">{region_errors.second_tax_rate_error}</FormHelperText>
                        <TextField
                            fullWidth
                            sx={textFieldStyle}
                            type="number"
                            size="small"
                            variant="outlined"
                            defaultValue={0.00}
                            id="second_tax_rate"
                            label="Second Tax Rate"
                            error={region_errors.second_tax_rate_has_error}
                            onChange={(e) => saveValue("second_tax_rate", e.target.value)} />
                        <FormHelperText error id="default_expiration_days_error">{region_errors.quote_expiration_days_error}</FormHelperText>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            type="number"
                            size="small"
                            variant="outlined"
                            defaultValue={30}
                            id="default_expiration_days"
                            label="Default Expiration Days"
                            error={region_errors.quote_expiration_days_has_error}
                            onChange={(e) => saveValue("default_expiration_days", e.target.value)} />
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            select
                            id="round_quotes"
                            label="Round Quotes"
                            defaultValue="C"
                            onChange={(e) => saveValue("round_quotes", e.target.value)}>
                                <MenuItem key={'C'} value={'C'}>
                                    {"$0.01"}
                                </MenuItem>
                                <MenuItem key={'D'} value={'D'}>
                                    {"$1.00"}
                                </MenuItem>
                        </TextField>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            multiline
                            minRows={3}
                            size="small"
                            variant="outlined"
                            id="default_payment_terms"
                            label="Default Payment Terms"
                            onChange={(e) => saveValue("default_payment_terms", e.target.value)} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
