import { Material } from 'api/types/material';
import { Color } from 'api/types/material';
import { Action } from 'api/action_types/material';
import { ActionType } from 'api/action_types/material';

interface State {
    materials: Material[],
    material: Material,
    color: Color,
    loading: boolean,
    error: string | null
}

const initialState = {
    color: {},
    materials: [],
    material: {},
    loading: false,
    error: null
}

export const materialReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.CLEAR_MATERIALS:
            return {
                ...state,
                materials: [],
                material: {}
            }
        case ActionType.GET_MATERIALS_SUCCESS:
            return {
                ...state,
                materials: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.GET_MATERIAL_SUCCESS:
            return {
                ...state,
                material: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.CREATE_MATERIAL_SUCCESS:
            return {
                ...state,
                material: action.payload,
                materials: [...state.materials, action.payload],
                loading: false,
                error: ''
            } 
        case ActionType.CREATE_COLOR_SUCCESS:
            return {
                ...state,
                color: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.UPDATE_MATERIAL_SUCCESS:
            return {
                ...state,
                material: action.payload,
                loading: false,
                error: ''
            }
        case ActionType.UPDATE_COLOR_SUCCESS:
            return {
                ...state,
                loading: false,
                color: action.payload
            } 
        case ActionType.DELETE_MATERIAL_SUCCESS:
            return {
                ...state,
                loading: false
            }     
        case ActionType.DELETE_COLOR_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActionType.UPDATE_LOCAL_COLOR:
            const materials: Material[] = JSON.parse(JSON.stringify(state.materials));
            for(let i = 0; i < materials.length; i++){
                const material: Material = materials[i];
                if(material.color_data){
                    for(let j = 0; j < material.color_data.length; j++){
                        if(material.color_data[j].id === action.payload.id){
                            material.color_data[j] = action.payload;
                            break;
                        }
                    }
                }
            }
            return {
                ...state,
                loading: true,
                materials: materials
            }
        case ActionType.SET_MATERIAL:
            return {
                ...state,
                material: action.payload
            }
        case ActionType.UPDATE_MATERIAL_PENDING:
        case ActionType.UPDATE_COLOR_PENDING:
        case ActionType.GET_MATERIALS_PENDING:
        case ActionType.GET_MATERIAL_PENDING:
        case ActionType.CREATE_MATERIAL_PENDING:
        case ActionType.CREATE_COLOR_PENDING:
        case ActionType.DELETE_MATERIAL_PENDING:
        case ActionType.DELETE_COLOR_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.GET_MATERIALS_FAIL:
        case ActionType.GET_MATERIAL_FAIL:
        case ActionType.CREATE_MATERIAL_FAIL:
        case ActionType.CREATE_COLOR_FAIL:
        case ActionType.UPDATE_MATERIAL_FAIL:
        case ActionType.UPDATE_COLOR_FAIL:
        case ActionType.DELETE_MATERIAL_FAIL:
        case ActionType.DELETE_COLOR_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }  
        default:
            return state;
    }
}



