import { useAppDispatch } from 'api/hooks/apiHook';
import { getMaterial } from "api/actions/material/material";
import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { Material } from "api/types/material";
import { drawerListItemStyle } from 'styles/drawer';
import { drawerHighlightStyle } from 'styles/drawer';
import { drawerHighlightTextStyle } from 'styles/drawer';
import { getMaterialHeightDisplay } from 'values/material';

interface Props {
    index: number,
    current_index: number,
    material: Material,
    setNavigation: (navigation: number) => void
}

export default function DrawerMaterialItem({index, current_index, material, setNavigation}: Props) {
    const dispatch = useAppDispatch();

    const getListItemStyle = () => {
        if(index === current_index){
            return drawerHighlightStyle;
        }
        return null;
    }

    const getListItemTextStyle = () => {
        if(index === current_index){
            return drawerHighlightTextStyle
        }
        return null;
    }

    const handleNavigation = () => {
        setNavigation(index);
        if(material.id){
            dispatch(getMaterial(material.id));
        }
    }

    return(
        <List sx={{padding: 0}}>
            <ListItem
                sx={[drawerListItemStyle, getListItemStyle()]}
                key={material.name}>
                <ListItemButton onClick={handleNavigation}>
                    <ListItemText  
                        primary={<Typography variant="body2" 
                        sx={getListItemTextStyle()}
                        >{material.name} {getMaterialHeightDisplay(material.thickness)} </Typography>} />
                </ListItemButton>
            </ListItem>
        </List>
    )
}
