export const QUARTZ = "Q";
export const NATURAL_STONE = "N";
export const ULTRA_COMPACT = "U";

export const material_type_list = [QUARTZ, NATURAL_STONE];

export function getMaterialTypeDisplay(material_type: string | undefined){
    if(!material_type){
        return "";
    }
    
    switch(material_type){
        case QUARTZ:
            return "Quartz";
        case NATURAL_STONE:
            return "Natural Stone";
        case ULTRA_COMPACT:
            return "Ultra Compact";
        default:
            return "";
    }
}

export const CM2 = "2";
export const CM3 = "3";
export const PORCELAIN = "P";

export function getMaterialHeightDisplay(material_thickness: string | undefined){
    if(!material_thickness){
        return "";
    }
    
    switch(material_thickness){
        case CM2:
            return "2CM";
        case CM3:
            return "3CM";
        case PORCELAIN:
            return "Porcelain";
        default:
            return "";
    }
}
