import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getRegions } from "api/actions/price";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import List from "components/universal/list";
import { islandStyle } from "styles/style";
import { mainContentAreaStyle } from "styles/style";
import Title from "components/universal/title";
import RegionsHeader from "./header/regions";
import FilterRegions from "./filter/regions";
import CreateRegion from "./dialog/create_region";
import RegionItem from "./row/region_item";
import { default_pagination_length } from "styles/table";

export default function Regions() {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(1);
    const { regions } = useAppSelector((state) => state.price);

    useEffect(() => {
        dispatch(getRegions(false));
    }, [dispatch]);

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return(
        <Box sx={mainContentAreaStyle}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <Title 
                        title="Regions"
                        subtitle={`${regions.length} Results`}
                        update_pending={false}
                        start_times={[]}
                        update={() => void 0}
                        endProps={<CreateRegion />} />
                </Grid>
                <Grid item xs={8.5}>
                    <Paper sx={islandStyle}> 
                        <Grid container>
                            <RegionsHeader />
                            <List 
                                items={regions.slice((page - 1) * default_pagination_length, page * default_pagination_length)}
                                Item={RegionItem}/>
                            <Grid item xs={12} sx={{marginTop: 3}}>
                                <Box display="flex" justifyContent="center">
                                    <Pagination
                                        onChange={changePage} 
                                        count={
                                            regions.length > default_pagination_length ? 
                                            Math.ceil(regions.length / default_pagination_length) : 
                                            1
                                        } shape="rounded" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={islandStyle}>
                        <FilterRegions />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
