
import type { Action as RegionAction } from "./region";
import type { Action as PriceListAction } from "./price_list";
import type { Action as SplashAction } from "./splash";
import type { Action as FabricationChargeAction } from "./fabrication_charge";
import type { Action as InstallationChargeAction } from "./installation_charge";
import type { Action as FinishedEdgeAction } from "./finished_edge";
import type { Action as ApplianceEdgeAction } from "./appliance_edge";
import type { Action as SinkCutoutAction } from "./sink_cutout";
import type { Action as CooktopCutoutAction } from "./cooktop_cutout";
import type { Action as FaucetCutoutAction } from "./faucet_cutout"
import type { Action as OutletCutoutAction } from "./outlet_cutout";
import type { Action as FullArcAction } from "./full_arc";
import type { Action as NotchedCornerAction } from "./notched_corner";
import type { Action as BumpedOutCornerAction } from "./bumped_out_corner";
import type { Action as ClippedCornerAction } from "./clipped_corner";
import type { Action as BumpOutArcAction } from "./bump_out_arc";
import type { Action as BumpInArcAction } from "./bump_in_arc";
import type { Action as BumpOutAction } from "./bump_out";
import type { Action as BumpInAction } from "./bump_in";
import type { Action as MaterialToPriceListAction } from "./material_to_price_list";
import type { Action as RadiusCornerAction } from "./radius_corner";
import type { Action as InsideDiagonalCornerAction } from "./inside_diagonal_corner";
import type { Action as InsideRadiusCornerAction } from "./inside_radius_corner";
import type { Action as RecessedDiagonalCornerAction } from "./recessed_diagonal_corner";
import type { Action as CountertopAction } from "./countertop_removal";

import { ActionType as RegionActionType } from "./region";
import { ActionType as PriceListActionType } from "./price_list";
import { ActionType as SplashActionType } from "./splash";
import { ActionType as FabricationChargeActionType } from "./fabrication_charge";
import { ActionType as InstallationChargeActionType } from "./installation_charge"; 
import { ActionType as FinishedEdgeActionType } from "./finished_edge";
import { ActionType as ApplianceEdgeActionType } from "./appliance_edge";
import { ActionType as SinkCutoutActionType } from "./sink_cutout";
import { ActionType as CooktopCutoutActionType } from "./cooktop_cutout";
import { ActionType as FaucetCutoutActionType } from "./faucet_cutout";
import { ActionType as OutletCutoutActionType } from "./outlet_cutout";
import { ActionType as FullArcActionType } from "./full_arc";
import { ActionType as NotchedCornerActionType } from "./notched_corner";
import { ActionType as BumpedOutCornerActionType } from "./bumped_out_corner";
import { ActionType as ClippedCornerActionType } from "./clipped_corner";
import { ActionType as BumpOutArcActionType } from "./bump_out_arc";
import { ActionType as BumpInArcActionType } from "./bump_in_arc";
import { ActionType as BumpOutActionType } from "./bump_out";
import { ActionType as BumpInActionType } from "./bump_in"; 
import { ActionType as MaterialToPriceListActionType } from "./material_to_price_list";
import { ActionType  as RadiusCornerActionType } from "./radius_corner";
import { ActionType as InsideDiagonalCornerActionType } from "./inside_diagonal_corner";
import { ActionType as InsideRadiusCornerActionType } from "./inside_radius_corner";
import { ActionType as RecessedDiagonalCornerActionType } from "./recessed_diagonal_corner";
import { ActionType as CountertopActionType } from "./countertop_removal";

export const ActionType = {
    ...RegionActionType,
    ...PriceListActionType,
    ...SplashActionType,
    ...FabricationChargeActionType,
    ...InstallationChargeActionType,
    ...FinishedEdgeActionType,
    ...ApplianceEdgeActionType,
    ...SinkCutoutActionType,
    ...CooktopCutoutActionType,
    ...FaucetCutoutActionType,
    ...OutletCutoutActionType,
    ...FullArcActionType,
    ...NotchedCornerActionType,
    ...BumpedOutCornerActionType,
    ...ClippedCornerActionType,
    ...BumpOutArcActionType,
    ...BumpInArcActionType,
    ...BumpOutActionType,
    ...BumpInActionType,
    ...MaterialToPriceListActionType,
    ...RadiusCornerActionType,
    ...InsideDiagonalCornerActionType,
    ...InsideRadiusCornerActionType,
    ...RecessedDiagonalCornerActionType,
    ...CountertopActionType
}

export type Action = 
    RegionAction | 
    PriceListAction |
    SplashAction |
    FabricationChargeAction |
    InstallationChargeAction |
    FinishedEdgeAction |
    SinkCutoutAction |
    CooktopCutoutAction |
    ApplianceEdgeAction |
    FaucetCutoutAction |
    OutletCutoutAction |
    FullArcAction |
    NotchedCornerAction |
    ClippedCornerAction |
    BumpedOutCornerAction |
    BumpInArcAction |
    BumpOutArcAction |
    BumpInAction |
    BumpOutAction |
    MaterialToPriceListAction |
    RadiusCornerAction |
    InsideRadiusCornerAction |
    InsideDiagonalCornerAction |
    RecessedDiagonalCornerAction |
    CountertopAction;

