import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { register } from "api/actions/authentication";
import { getRegions } from "api/actions/price";
import { setRegistrationComplete } from "api/actions/authentication";
import { setBillingCustomerCreated } from "api/actions/billing";
import { Registration } from "api/types/authentication";
import { RegistrationErrors } from "api/types/authentication";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { getLatLng } from "react-google-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { validateEmail } from "functions/value";
import { validatePhoneNumber } from "functions/value";
import { error_color } from "styles/style";
import { grey_color } from "styles/style";
import { text_primary } from "styles/style";
import { GOOGLE_API_KEY } from "settings";

interface Props {
    setStep: (step: number) => void
}

export default function RegistrationComponent({setStep}: Props) {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector((state) => state.authentication);
    const { error } = useAppSelector((state) => state.authentication);
    const { registration_complete } = useAppSelector((state) => state.authentication);
    const { regions } = useAppSelector((state) => state.price);
    const [registration, setRegistration] = useState<Registration>({
        geo_address: "",
        lat: 0,
        lng: 0,
        region: 0,
        email: "",
        first_name: "",
        last_name: "",
        business_name: "",
        phone_number: "",
        address: "",
        city: "",
        province: "",
        postal_code: "",
        is_fabricator: false,
        is_distributor: true
    });

    const [registration_error, setRegistrationError] = useState<RegistrationErrors>({
        geo_error: "",
        geo_has_error: false,
        region_error: "",
        region_has_error: false,
        email_error: "",
        email_has_error: false,
        first_name_error: "",
        first_name_has_error: false,
        last_name_error: "",
        last_name_has_error: false,
        business_name_error: "",
        business_name_has_error: false,
        address_error: "",
        address_has_error: false,
        city_error: "",
        city_has_error: false,
        postal_code_error: "",
        postal_code_has_error: false,
        phone_number_error: "",
        phone_number_has_error: false
    });

    useEffect(() => {
        dispatch(getRegions(true));
        setRegistrationComplete(false);
        setBillingCustomerCreated(false);
    }, []);

    const setMapValue = (e: any) => {
        geocodeByAddress(e.label)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            setRegistration({
                ...registration,
                lng:  Math.round(lng * 100000) / 100000,
                lat:  Math.round(lat * 100000) / 100000,
                geo_address: e.label,
                geo_found: true
            });
        });
    }

    if(registration_complete){
        setStep(1);
    }

    const saveString = (key: string, value: string) => {
        setRegistration({...registration, [key]: value});
    }

    const saveInt = (key: string, value: number) => {
        setRegistration({...registration, [key]: value})
    }

    const removeError = (key: string) => {
        setRegistrationError({...registration_error, [`${key}_error`]: "", [`${key}_has_error`]: false});
    }

    const handleAccountType = (type: string) => {
        if(type === "D"){
            setRegistration({...registration, is_fabricator: false, is_distributor: true});
        }
        else if(type === "F"){
            setRegistration({...registration, is_fabricator: true, is_distributor: false});
        }
    }

    const handleRegistration = () => {
        let error: boolean = false;
        const new_registration_error = {...registration_error};

        if(!validateEmail(registration.email)){
            new_registration_error.email_error = "Enter a valid email address";
            new_registration_error.email_has_error = true;
            error = true;
        }

        if(!validatePhoneNumber(registration.phone_number)){
            new_registration_error.phone_number_error = "Enter a valid phone number";
            new_registration_error.phone_number_has_error = true;
            error = true;
        }

        if(registration.geo_address === "" || registration.lat === 0 || registration.lng === 0){
            new_registration_error.geo_error = "Must enter a service address for your location";
            new_registration_error.geo_has_error = true;
            error = true;
        }

        if(registration.region === 0){
            new_registration_error.region_error = "Must choose a service region";
            new_registration_error.region_has_error = true;
            error = true;
        }

        if(registration.first_name === ""){
            new_registration_error.first_name_error = "Must enter a first name";
            new_registration_error.first_name_has_error = true;
            error = true;
        }

        if(registration.last_name === ""){
            new_registration_error.last_name_error = "Must enter a last name";
            new_registration_error.last_name_has_error = true;
            error = true;
        }

        if(registration.business_name === ""){
            new_registration_error.business_name_error = "Must enter a business name";
            new_registration_error.business_name_has_error = true;
            error = true;
        }

        if(registration.address === ""){
            new_registration_error.address_error = "Must enter an address";
            new_registration_error.address_has_error = true;
            error = true;
        }

        if(registration.city === ""){
            new_registration_error.city_error = "Must enter a city";
            new_registration_error.city_has_error = true;
            error = true;
        }

        if(registration.postal_code === ""){
            new_registration_error.postal_code_error = "Must enter a postal code";
            new_registration_error.postal_code_has_error = true;
            error = true;
        }

        setRegistrationError(new_registration_error);
        
        if(error){
            return;
        }
        
        dispatch(register(registration));
    }

    return (
        <Grid container item sx={{width: "100%", paddingLeft: 3.5, paddingRight: 3.5}}>
            {
                registration_complete ?
                <Box 
                    role="presentation">
                        <Typography variant="h6">
                            Account registration successful. Customer should check their email for a confirmation email. You can complete the rest of the registration immediately.
                        </Typography>
                </Box> :
                <Fragment>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{color: text_primary}}>
                            Account Creation
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" sx={{color: error_color, paddingLeft: 1.5, paddingBottom: 2}}>
                                {error} 
                            </Typography>  
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{marginBottom: 0.75}}>
                        <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5}}>
                            Account
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            select
                            defaultValue="D"
                            size="small"
                            label="Account Type"
                            id="account"
                            onChange={(e) => handleAccountType(e.target.value)}>
                                <MenuItem key="D" value="D">
                                    Dealer
                                </MenuItem>
                                <MenuItem key="F" value="F">
                                    Fabricator
                                </MenuItem>
                            </TextField>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={12} sx={{
                        marginBottom: 
                            registration_error.first_name_has_error || 
                            registration_error.last_name_has_error ? 0 : 0.5 }}>
                        <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5, paddingTop: 1}}>
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.first_name_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.last_name_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("first_name")}
                            error={registration_error.first_name_has_error}
                            size="small"
                            label="First Name"
                            id="first_name"
                            onChange={e => saveString("first_name", e.target.value)} />
                    </Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField 
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("last_name")}
                            error={registration_error.last_name_has_error}
                            size="small"
                            label="Last Name"
                            id="last_name"
                            onChange={e => saveString("last_name", e.target.value)} />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12} sx={{marginBottom: registration_error.business_name_has_error ? 0 : 0.5 }}>
                        <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5, paddingTop: 1}}>
                            Service Location
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHelperText error>{registration_error.geo_error}</FormHelperText>
                    </Grid>
                    {
                        GOOGLE_API_KEY && GOOGLE_API_KEY !== "" ?
                        <Grid item xs={12} sx={{marginBottom: 1}}>
                            <GooglePlacesAutocomplete
                                apiOptions={{
                                    libraries: ["places"],
                                    region: "ca"
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['ca'],
                                    }
                                }}
                                selectProps={{
                                    placeholder: "Service Address",
                                    onChange: (place) => setMapValue(place),
                                }}/>
                        
                        </Grid> :
                        null
                    }
                    <Grid item xs={12}>
                        <FormHelperText error>{registration_error.region_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("region")}
                            error={registration_error.business_name_has_error}
                            size="small"
                            label="Region"
                            select
                            id="Region"
                            onChange={e => saveInt("region", Number(e.target.value))}>
                            {
                                regions.map(region => {
                                    return (
                                        <MenuItem key={region.id} value={region.id}>
                                            {region.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={12} sx={{marginBottom: registration_error.business_name_has_error ? 0 : 0.5 }}>
                        <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5, paddingTop: 1}}>
                            Business
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.business_name_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("business_name")}
                            error={registration_error.business_name_has_error}
                            size="small"
                            label="Business Name"
                            id="business_name"
                            onChange={e => saveString("business_name", e.target.value)} />
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.address_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.city_error}</FormHelperText>  
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.postal_code_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("address")}
                            error={registration_error.address_has_error}
                            size="small"
                            label="Business Address"
                            id="address"
                            onChange={e => saveString("address", e.target.value)} />
                    </Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("city")}
                            error={registration_error.city_has_error}
                            size="small"
                            label="City"
                            id="city"
                            onChange={e => saveString("city", e.target.value)} />
                    </Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            select
                            defaultValue="AB"
                            size="small"
                            label="Province"
                            id="province"
                            onChange={e => saveString("province", e.target.value)}>
                            <MenuItem key="AB" value="AB">
                                AB
                            </MenuItem>
                            <MenuItem key="ON" value="ON">
                                ON
                            </MenuItem>
                            <MenuItem key="BC" value="BC">
                                BC
                            </MenuItem>
                            <MenuItem key="MB" value="MB">
                                MB
                            </MenuItem>
                            <MenuItem key="SK" value="SK">
                                SK
                            </MenuItem>
                            <MenuItem key="QC" value="QC">
                                QC
                            </MenuItem>
                            <MenuItem key="NS" value="NS">
                                NS
                            </MenuItem>
                            <MenuItem key="PE" value="PE">
                                PE
                            </MenuItem>
                            <MenuItem key="NL" value="NL">
                                NL
                            </MenuItem>
                            <MenuItem key="NB" value="NB">
                                NB
                            </MenuItem>
                            <MenuItem key="YT" value="YT">
                                YT
                            </MenuItem>
                            <MenuItem key="NU" value="NU">
                                NU
                            </MenuItem>
                            <MenuItem key="NT" value="NT">
                                NT
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("postal_code")}
                            error={registration_error.postal_code_has_error}
                            size="small"
                            label="Postal Code"
                            id="postal_code"
                            onChange={e => saveString("postal_code", e.target.value)} />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12} sx={{
                        marginBottom: 
                            registration_error.email_has_error || 
                            registration_error.phone_number_has_error ? 0 : 0.5 }}>
                        <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5, paddingTop: 1}}>
                            Contact
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.email_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText error>{registration_error.phone_number_error}</FormHelperText>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("email")}
                            error={registration_error.email_has_error}
                            size="small"
                            label="Email"
                            id="email"
                            onChange={e => saveString("email", e.target.value)} />
                    </Grid>
                    <Grid item xs={3} sx={{paddingRight: 1}}>
                        <TextField
                            fullWidth
                            sx={{marginBottom: 1}}
                            onFocus={() => removeError("phone_number")}
                            error={registration_error.phone_number_has_error}
                            size="small"
                            label="Phone: 555-555-5555"
                            id="phone_number"
                            onChange={e => saveString("phone_number", e.target.value)} />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" sx={{marginTop: 20}}>
                            {
                                loading ? 
                                <CircularProgress /> :
                                <Button variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={handleRegistration} >
                                    Submit
                                </Button>
                            }
                        </Box>
                    </Grid>
                </Fragment>
            }
       </Grid>  
    );
}
