import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { Region } from "api/types/price";
import { getRowSecondStyle } from "values/style";
import { rowItemStyle } from 'styles/style';
import { region_url } from 'components/navigation/endpoints';

interface Props {
    index: number,
    item: Region
}

export default function RegionItem({index, item}: Props) {
    const navigate = useNavigate();

    return(
        <Grid 
            container 
            item
            onClick={(e) => navigate(`/${region_url}/${item.id}`, {replace: false})}
            sx={[rowItemStyle, getRowSecondStyle(index)]}>
            <Grid item xs={2}>
                <Typography variant="body2">
                    {item.name}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" align="right">
                    {item.quote_expiration_days}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2" align="right">
                    {item.default_tax_rate}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2" align="right">
                    {item.enabled === true ? "Active" : "Inactive"}
                </Typography>
            </Grid>
        </Grid>
    )
}
