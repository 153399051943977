import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Material } from 'api/types/material';
import { getMaterialHeightDisplay } from "values/material";
import { getRowSecondStyle } from "values/style";

interface Props {
    index: number,
    item: Material
}

export default function ManageSuppliersItem({index, item}: Props) {
    return(
        <Grid item container alignItems="center" sx={[getRowSecondStyle(index), {padding: 0.5}]}>
            <Grid item xs={6}>
                <Typography variant="body2">
                    {item && item.name ? item.name : ""} {item ? getMaterialHeightDisplay(item.thickness) : ""}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2">
                    {item && item.supplier_data ? item.supplier_data.business_name : ""}
                </Typography>
            </Grid>
        </Grid>
    )
}
