import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { PriceList } from "api/types/price";
import { price_list_url } from "components/navigation/endpoints";
import { rowItemStyle } from "styles/style";
import { getMeasurementType } from "values/values";
import { getRowSecondStyle } from 'values/style';

interface Props {
    index: number,
    item: PriceList
}

export default function PriceListsItem({index, item}: Props) {
    const navigate = useNavigate();

    const getStatus = (status: boolean | undefined) => {
        if(status === undefined || status === null){
            return "Unknown";
        }

        if(status){
            return "Active";
        }
        else{
            return "Inactive";
        }
    }

    return(
        <Grid container item xs={12}
            justifyContent="flex-start"
            sx={[rowItemStyle, getRowSecondStyle(index)]}
            onClick={(e) => navigate(`/${price_list_url}/${item.id}`, {replace: false})} >
                <Grid item xs={2}> 
                    <Typography variant="body2">
                        {item.name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2">
                        {item.region_data ? item.region_data.name : ""}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                        {getStatus(item.status)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" align="right">
                        {getMeasurementType(item.units)}
                    </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
        </Grid>
    )
}
