import { PriceDetailError } from "api/types/price";
import { PriceDetailHasError } from "api/types/price";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ApplicationScope from "components/price/dialog/shared_components/application_scope";
import Options from "components/price/dialog/shared_components/options";
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
    title: string,
    open: boolean,
    errors: PriceDetailError,
    has_errors: PriceDetailHasError
    close: () => void,
    create: () => void,
    saveString: (key: string, value: string) => void,
    saveNumber: (key: string, value: string) => void,
    saveBoolean: (key: string, value: boolean) => void
}

export default function CreateBasic({
    title,
    open,
    errors,
    has_errors,
    close,
    create,
    saveString,
    saveBoolean,
    saveNumber
}: Props) {
    return(
        <Dialog 
            fullWidth={true}
            maxWidth={"md"}
            open={open}>
            <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>{title}</DialogTitle>
            <DialogContent sx={dialogContentStyle}>
                <FormControl fullWidth sx={{paddingTop: 1}}>
                    <FormHelperText error id="price_list_error">{errors.price_list}</FormHelperText>
                    <ApplicationScope saveString={saveString} />
                    <FormHelperText error id="price">{errors.price}</FormHelperText>
                    <TextField
                        sx={textFieldStyle}
                        fullWidth
                        size="small"
                        variant="outlined"
                        id="price"
                        label="Price"
                        type="number"
                        defaultValue={0}
                        error={has_errors.price}
                        onChange={(e) => saveNumber("price", e.target.value)} />
                    <Options 
                        saveBoolean={saveBoolean} />
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={create}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}
