import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { getUploadInstance } from 'api/actions/material_import';
import Title from "components/universal/title";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import UploadErrorHeader from './header/upload_error';
import List from 'components/universal/list';
import CreateUpload from './dialog/create_upload';
import ErrorRow from './row/error';
import { islandStyle } from "styles/style";
import { mainContentAreaStyle } from 'styles/style';
import { getMaterialImportStatusDisplay } from 'values/import_material';

export default function UploadInstanceComponent() {
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const { upload } = useAppSelector((state) => state.material_import);

    useEffect(() => {
        if(id){
            dispatch(getUploadInstance(id));
        }
    }, [dispatch, id]);

    return(
        <Box sx={mainContentAreaStyle}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <Title 
                        title={getMaterialImportStatusDisplay(upload.resolution)}
                        subtitle={`By ${upload.username} on ${upload.date}`}
                        update_pending={false}
                        start_times={[]}
                        update={() => void 0}
                        endProps={<CreateUpload />} />
                </Grid>
                <Grid item xs={8.5}>
                    <Paper sx={islandStyle}>
                        <UploadErrorHeader />
                        <List 
                            items={upload.errors}
                            Item={ErrorRow} />
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </Grid>
        </Box>
    )
}
