import List from "components/universal/list";
import Grid from '@mui/material/Grid';
import SplashRangePlainItem from "./splash_range";
import BasicPlainRangeItem from "./shared/basic_range";
import { Splash } from "api/types/price";
import { getRowSecondStyle } from "values/style";

interface Props {
    index: number,
    item: Splash
}

export default function SplashPlainItem({index, item}: Props) {
    return(
        <Grid container item sx={[{padding: 1}, getRowSecondStyle(index)]} alignItems="center">
            <BasicPlainRangeItem 
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.taxable ? item.taxable : false}/>
            <List 
                items={item.ranges ? item.ranges : []}
                Item={SplashRangePlainItem} />
        </Grid>
    )
}
