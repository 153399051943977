import { Region } from "api/types/price";

export enum ActionType {
    GET_REGIONS_PENDING = "GET_REGIONS_PENDING",
    GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS",
    GET_REGIONS_FAIL = "GET_REGIONS_FAIL",
    GET_REGION_PENDING = "GET_REGION_PENDING",
    GET_REGION_SUCCESS = "GET_REGION_SUCCESS",
    GET_REGION_FAIL = "GET_REGION_FAIL",
    CREATE_REGION_PENDING = "CREATE_REGION_PENDING",
    CREATE_REGION_SUCCESS = "CREATE_REGION_SUCCESS",
    CREATE_REGION_FAIL = "CREATE_REGION_FAIL",
    UPDATE_REGION_PENDING = "UPDATE_REGION_PENDING",
    UPDATE_REGION_SUCCESS = "UPDATE_REGION_SUCCESS",
    UPDATE_REGION_FAIL = "UPDATE_REGION_FAIL",
    DELETE_REGION_PENDING = "DELETE_REGION_PENDING",
    DELETE_REGION_SUCCESS = "DELETE_REGION_SUCCESS",
    DELETE_REGION_FAIL = "DELETE_REGION_FAIL"
}

interface getRegionsPending {
    type: ActionType.GET_REGIONS_PENDING
}

interface getRegionsSuccess {
    type: ActionType.GET_REGIONS_SUCCESS,
    payload: Region[]
}

interface getRegionsFail {
    type: ActionType.GET_REGIONS_FAIL,
    payload: string
}

interface getRegionPending {
    type: ActionType.GET_REGION_PENDING
}

interface getRegionSuccess {
    type: ActionType.GET_REGION_SUCCESS,
    payload: Region
}

interface getRegionFail {
    type: ActionType.GET_REGION_FAIL,
    payload: string
}

interface createRegionPending {
    type: ActionType.CREATE_REGION_PENDING
}

interface createRegionSuccess {
    type: ActionType.CREATE_REGION_SUCCESS,
    payload: Region
}

interface createRegionFail {
    type: ActionType.CREATE_REGION_FAIL,
    payload: string
}

interface updateRegionPending {
    type: ActionType.UPDATE_REGION_PENDING
}

interface updateRegionSuccess {
    type: ActionType.UPDATE_REGION_SUCCESS,
    payload: Region
}

interface updateRegionFail {
    type: ActionType.UPDATE_REGION_FAIL,
    payload: string
}

interface deleteRegionPending {
    type: ActionType.DELETE_REGION_PENDING
}

interface deleteRegionSuccess {
    type: ActionType.DELETE_REGION_SUCCESS,
    payload: Region
}

interface deleteRegionFail {
    type: ActionType.DELETE_REGION_FAIL,
    payload: string
}

export type Action = 
    getRegionsPending |
    getRegionsSuccess |
    getRegionsFail |
    getRegionPending |
    getRegionSuccess |
    getRegionFail |
    createRegionPending |
    createRegionSuccess |
    createRegionFail |
    updateRegionPending |
    updateRegionSuccess |
    updateRegionFail |
    deleteRegionPending |
    deleteRegionSuccess |
    deleteRegionFail;