import Name from "../shared/name";
import Checkboxes from "./shared/checkboxes";
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FinishedEdgeTypeItem from "./shared/finished_edge_type";
import { FinishedEdge } from "api/types/price"; 
import { gridSpacing } from "styles/style";
import { getRowSecondStyle } from 'values/style';
import { getMaterialHeightDisplay } from "values/material";
import { getLamination } from "values/values";
import { EASED } from "values/values";
import { BEVEL } from "values/values";
import { SPECIALTY } from "values/values";
import { BULLNOSE } from "values/values";
import { MITER } from "values/values";
import { NONE } from "values/values";
import { DOUBLE } from "values/values";
import { TRIPLE } from "values/values";

interface Props {
    index: number,
    item: FinishedEdge
}

export default function FinishedEdgePlainItem({index, item}: Props) {

    return(
        <Grid container item alignItems="center" sx={[{padding: 1}, getRowSecondStyle(index)]}>
            <Name 
                bold={false}
                name={getMaterialHeightDisplay(item.material_thickness)} 
                width={6} />
            <Grid item xs={2} sx={gridSpacing}>
                <TextField
                    size="small"
                    disabled
                    label="Lamination"
                    defaultValue={item.lamination}
                    select>
                        <MenuItem key={NONE} value={NONE}>
                            {getLamination(NONE)}
                        </MenuItem>
                        <MenuItem key={DOUBLE} value={DOUBLE}>
                            {getLamination(DOUBLE)}
                        </MenuItem>
                        <MenuItem key={TRIPLE} value={TRIPLE}>
                            {getLamination(TRIPLE)}
                        </MenuItem>
                </TextField>
            </Grid>
            <Checkboxes
                grid_width={4}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.taxable ? item.taxable : false} />
            <FinishedEdgeTypeItem 
                name="Eased" 
                type={EASED}
                price={item.eased_price ? item.eased_price : 0}
                enabled={item.enabled ? item.enabled : false} />
            <FinishedEdgeTypeItem 
                name="Bevel" 
                type={BEVEL}
                price={item.bevel_price ? item.bevel_price : 0}
                enabled={item.enabled ? item.enabled : false} />
            <FinishedEdgeTypeItem 
                name="Ogee" 
                type={SPECIALTY}
                price={item.specialty_price ? item.specialty_price : 0}
                enabled={item.enabled ? item.enabled : false} />
            <FinishedEdgeTypeItem 
                name="Miter" 
                type={MITER}
                price={item.miter_price ? item.miter_price : 0}
                enabled={item.enabled ? item.enabled : false} />
            <FinishedEdgeTypeItem 
                name="Bullnose" 
                type={BULLNOSE}
                price={item.bullnose_price ? item.bullnose_price : 0}
                enabled={item.enabled ? item.enabled : false} />
        </Grid>
    )
}
